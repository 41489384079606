<template>
  <section class="section__home">
    <v-dialog max-width="600px" v-model="showEditLink">
      <v-card :loading="loading">
        <v-card-title>ویرایش لینک</v-card-title>
        <v-form ref="formEdit">
          <v-card-text>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="editData.data.caption"
                  label="عنوان لینک"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="editData.data.url"
                  label="آدرس"
                  outlined
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
        </v-form>
        <v-card-actions>
          <v-btn v-on:click="editLink">ویرایش</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-container fluid>
      <v-row>
        <v-col cols="12" lg="12">
          <v-row>
            <v-col cols="12" md="9" lg="10" class="section__home-data px-5">
              <v-card class="fill-height rounded-lg">
                <v-container>
                  <v-row class="pb-3">
                    <!-- ستون سمت راست -->
                    <HomeBox />
                    <FavoritePerson />
                    <!-- <TaskList /> -->
                  </v-row>
                  <v-row class="dense">
                    <HomeMyMessage />
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="section__home-sidbar">
              <MenuLeft v-on:sendEditLink="getEditLink"></MenuLeft>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import MenuLeft from "../../components/MenuLeft.vue";
import TaskList from "./TaskList.vue";
import HomeBox from "./HomeBox.vue";
import FavoritePerson from "./FavoritePerson.vue";
import axios from "axios";
import { mapGetters } from "vuex";
import HomeMyMessage from "./HomeMyMessage.vue";
import Project from "@/latelier/imports/ui/projects/Project.vue";
export default {
  name: "HomePage",
  components: {
    MenuLeft,
    TaskList,
    HomeBox,
    FavoritePerson,
    HomeMyMessage,
    Project,
  },
  data() {
    return {
      editData: { data: { Caption: null, Url: null } },
      showEditLink: false,
      loading: false,
    };
  },
  computed: {
    ...mapGetters(["getUrl"]),
  },
  mounted() {
    this.getList();
    window.devicePixelRatio = 0.8999999761581421;

    console.log(this.$vuetify.breakpoint);
  },
  methods: {
    editLink() {
      axios
        .post(`${this.getUrl}/link/update/`, {
          Id: this.editData.data.linkId,
          Caption: this.editData.data.caption,
          Url: this.editData.data.url,
          Language: 0,
        })
        .then((data) => {
          console.log(data, "data");
          if (data.data.status == 1) {
            this.$store.dispatch("sendList", { url: "link/list/", page: 1 });
            this.showEditLink = false;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getEditLink(item) {
      this.loading = true;
      console.log(item);
      this.showEditLink = item.showDialog;
      axios
        .get(`${this.getUrl}/link/get/`, {
          params: {
            Id: item.logoItem.linkId,
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response);
          this.editData.data = response.data.record;
          this.loading = false;
          console.log(this.editData.data);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getList() {
      this.$store.dispatch("sendListMenu", { url: "menu/list/" });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__home {
  background-color: #eeeeee !important;
}
.border {
  border: 1px solid black !important;
}
.color {
  background: transparent !important;
}

.search ::placeholder {
  font-size: 0.8rem !important;
}
.scrollable-card {
  height: 310px; /* ارتفاع ثابت */
  overflow-y: auto; /* امکان اسکرول در جهت عمودی */
}

::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

::-webkit-scrollbar-thumb {
  background-color: #c7c7c7;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.card-one {
  transition: 1s;
}
.card-one:hover {
  z-index: 1;
  transform: scale(1.1);
}
.wire {
  border: 4px solid #d1c4e9;
  min-width: 10px;
  max-width: 20px;
}
</style>
