<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            :items="getContractgroupList"
            :insertItemData="addData"
            v-on:getListBySearch="getSearch"
            v-on:getList="getPage"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="formAdd">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="گروه قرارداد"
                        hide-details
                        outlined
                        dense
                        v-model="addData.contractGroupTitle"
                        :rules="requiredRule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        label="هزینه ای"
                        hide-details
                        dense
                        v-model="addData.isCost"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="گروه قرارداد"
                        hide-details
                        outlined
                        dense
                        v-model="editData.data.contractGroupTitle"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-checkbox
                        label="هزینه ای"
                        hide-details
                        dense
                        v-model="editData.data.isCost"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";

export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      addData: {
        url: "contractgroup/create/",
        formName: "formAdd",
        contractGroupTitle: null,
        isCost: false,
      },
      editData: {
        url: "contractgroup/update/",
        formName: "formEdit",
        data: {
          contractGroupId: null,
          contractGroupTitle: null,
          isCost: false,
        },
      },
      removeData: { url: "contractgroup/delete/" },
      headers: [
        {
          text: "گروه قرارداد",
          align: "start",
          value: "contractGroupTitle",
        },
        {
          text: "نوع قرارداد",
          align: "start",
          value: "contractType",
        },
        { text: "", value: "actions", align: "end", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getContractgroupList", "getUrl"]),
  },
  created() {
    this.getContractCategory();
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "contractgroup/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "contractgroup/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getContractCategory() {
      this.$store.dispatch("sendList", {
        url: "contractgroup/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      axios
        .get(`${this.getUrl}/contractgroup/get/`, {
          params: {
            Id: item.contractGroupId,
            Language: 0,
          },
        })
        .then((response) => {
          this.editData.data = response.data.record;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
