import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueCookies from "vue-cookies";
Vue.config.productionTip = false;
import axios from "axios";
import i18n from "@/latelier/imports/i18n/";
import confirm from "@/latelier/imports/confirm/confirm";
import VueSignalR from "./components/vue-signalr.js";

//axios.defaults.withCredentials = true;
axios.interceptors.response.use(undefined, function (error) {
  if (error.response.status == 401 || error.response.status == 403) {
    store.dispatch("logout");
    return Promise.reject(error);
  }
});
// Set the AUTH token for any request
axios.interceptors.request.use(function (config) {
  const token = localStorage.getItem("token");
  config.headers.Authorization = token ? `Bearer ${token}` : "";
  return config;
});

Vue.use(require("vue-shortkey"));
Vue.use(confirm, { vuetify });
Vue.use(VueSignalR, store.getters.getBaseUrl + "NotificationHub");

new Vue({
  i18n,
  router,
  store,
  VueCookies,
  vuetify,
  VueSignalR,
  render: (h) => h(App),
}).$mount("#app");
