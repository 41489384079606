<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            :items="getblogList"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="form">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="نام صفحه"
                        outlined
                        dense
                        hide-details
                        v-model="addData.pageName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان"
                        outlined
                        dense
                        hide-details
                        v-model="addData.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="توضیح کوتاه"
                        outlined
                        dense
                        hide-details
                        v-model="addData.lead"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <quill-editor
                        label="متن"
                        v-model="addData.description"
                      ></quill-editor>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="عنوان متا"
                        outlined
                        dense
                        hide-details
                        v-model="addData.metaTitle"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="توضیح متا"
                        outlined
                        dense
                        hide-details
                        v-model="addData.metaDescription"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="کلید واژه های متا"
                        outlined
                        dense
                        hide-details
                        v-model="addData.metaKeyword"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="addData.relatedBlog"
                        :items="getblogList.records"
                        item-text="PageName"
                        item-value="BlogId"
                        outlined
                        dense
                        hide-details
                        chips
                        small-chips
                        label="محتوای مرتبط"
                        multiple
                      ></v-autocomplete>
                      <!-- <v-combobox
                      label="محتوای مرتبط"
                      outlined
                      dense
                      hide-details
                      v-model="addData.RelatedBlog"
                    ></v-combobox> -->
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        label="گروه محتوا"
                        :items="getBlogcategoryList.records"
                        item-text="title"
                        item-value="blogCategoryId"
                        outlined
                        dense
                        hide-details
                        v-model="addData.blogCategoryFk"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        v-model="addData.tags"
                        label="برچسب ها"
                        :search-input.sync="search"
                        hide-selected
                        multiple
                        outlined
                        dense
                        persistent-hint
                        small-chips
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No results matching "<strong>{{
                                  search
                                }}</strong
                                >". Press <kbd>enter</kbd> to create a new one
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                      <!-- <v-combobox
                      v-model="addData.Tags"
                      :filter="filter"
                      :hide-no-data="!search"
                      :items="items"
                      :search-input.sync="search"
                      hide-selected
                      label="برچسب ها"
                      multiple
                      small-chips
                      solo
                      outlined
                      dense
                    >
                      <template v-slot:no-data>
                        <v-list-item>
                          <span class="subheading">Create</span>
                          <v-chip
                          
                            label
                            small
                          >
                            {{ search }}
                          </v-chip>
                        </v-list-item>
                      </template>
                      <template
                        v-slot:selection="{ attrs, item, parent, selected }"
                      >
                        <v-chip
                          v-if="item === Object(item)"
                          v-bind="attrs"
                          :color="`${item.color} lighten-3`"
                          :input-value="selected"
                          label
                          small
                        >
                          <span class="pr-2">
                            {{ item.text }}
                          </span>
                          <v-icon small @click="parent.selectItem(item)">
                            $delete
                          </v-icon>
                        </v-chip>
                      </template>
                      <template v-slot:item="{ index, item }">
                        <v-text-field
                          v-if="editing === item"
                          v-model="editing.text"
                          autofocus
                          flat
                          background-color="transparent"
                          hide-details
                          solo
                          @keyup.enter="edit(index, item)"
                        ></v-text-field>
                        <v-chip
                          v-else
                          :color="`${item.color} lighten-3`"
                          dark
                          label
                          small
                        >
                          {{ item.text }}
                        </v-chip>
                        <v-spacer></v-spacer>
                        <v-list-item-action @click.stop>
                          <v-btn icon @click.stop.prevent="edit(index, item)">
                            <v-icon>{{
                              editing !== item ? "mdi-pencil" : "mdi-check"
                            }}</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </template>
                    </v-combobox> -->
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" lg="6">
                          <v-file-input
                            label="انتخاب عکس"
                            ref="fileInput"
                            v-model="image"
                            accept="image/*"
                            @change="uploadImage"
                            :clearable="false"
                          ></v-file-input>
                          <v-btn v-on:click="remove">حذف</v-btn>
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-img
                            min-width="200"
                            min-height="200"
                            ref="image"
                            max-width="300"
                            max-height="300"
                            :src="`https://crmapi.dayavo.com/${addData.cover}`"
                          ></v-img>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="نام صفحه"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.pageName"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="توضیح کوتاه"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.lead"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <quill-editor
                        label="متن"
                        v-model="editData.data.description"
                      ></quill-editor>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="عنوان متا"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.metaTitle"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="توضیح متا"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.metaDescription"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="کلید واژه های متا"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.metaKeyword"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-autocomplete
                        label="محتوای مرتبط"
                        v-model="editData.data.relatedBlog"
                        :items="getblogList.records"
                        item-text="pageName"
                        item-value="blogId"
                        outlined
                        dense
                        hide-details
                        chips
                        small-chips
                        multiple
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-select
                        label="گروه محتوا"
                        :items="getBlogcategoryList.records"
                        item-text="title"
                        item-value="blogCategoryId"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.blogCategoryFk"
                      ></v-select>
                    </v-col>
                    <v-col cols="12">
                      <v-combobox
                        v-model="editData.data.tags"
                        label="برچسب ها"
                        :search-input.sync="search"
                        hide-selected
                        multiple
                        outlined
                        dense
                        persistent-hint
                        small-chips
                      >
                        <template v-slot:no-data>
                          <v-list-item>
                            <v-list-item-content>
                              <v-list-item-title>
                                No results matching "<strong>{{
                                  search
                                }}</strong
                                >". Press <kbd>enter</kbd> to create a new one
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </template>
                      </v-combobox>
                    </v-col>
                    <v-col cols="12">
                      <v-row>
                        <v-col cols="12" lg="6">
                          <v-file-input
                            label="انتخاب عکس"
                            ref="fileInput"
                            v-model="image"
                            accept="image/*"
                            @change="uploadEditImage"
                          ></v-file-input>
                          <v-btn v-on:click="remove">حذف</v-btn>
                        </v-col>
                        <v-col cols="12" lg="6">
                          <v-img
                            min-width="200"
                            min-height="200"
                            ref="image"
                            max-width="300"
                            max-height="300"
                            :src="`https://crmapi.dayavo.com/${editData.data.cover}`"
                          ></v-img>
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import MenuLeft from "../../components/MenuLeft.vue";
import axios from "axios";
import AccountingSystem from "../../components/AccountingSystem.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
    quillEditor,
  },
  data() {
    return {
      searchTest: "",
      select: [],
      content: [],
      search: null,
      addData: {
        url: "blog/create/",
        pageName: null,
        blogCategoryFk: null,
        cover: null,
        description: null,
        lead: null,
        metaDescription: null,
        metaKeyword: null,
        metaTitle: null,
        title: null,
        relatedBlog: null,
        tags: null,
        test: this.$refs,
      },
      editData: {
        url: "blog/update/",
        data: {
          pageName: null,
          blogCategoryFk: null,
          cover: null,
          description: null,
          lead: null,
          metaDescription: null,
          metaKeyword: null,
          metaTitle: null,
          title: null,
          relatedBlog: null,
          tags: null,
        },
      },
      removeData: { url: "blog/delete/" },
      content: "",
      image: null,
      headers: [
        {
          text: "نام صفحه",
          align: "right",
          value: "pageName",
        },
        {
          text: "عنوان",
          align: "center",
          value: "title",
        },
        {
          text: "تعداد مشاهده",
          align: "center",
          value: "seen",
        },
        // {
        //   text: 'موبایل',
        //   align: 'start',
        //   value: 'Mobile',
        // },
        // {
        //   text: 'فعال',
        //   align: 'start',
        //   value: 'IsActive',
        // },
        // {
        //   text: 'شناسه ملی',
        //   align: 'start',
        //   value: 'NationalId',
        // },
        // {
        //   text: 'شماره تماس',
        //   align: 'start',
        //   value: 'Phone',
        // },
        // {
        //   text: 'شرکت مادر',
        //   align: 'start',
        //   value: 'ParentCompanyName',
        // },
        // {
        //   text: 'کد پستی',
        //   align: 'start',
        //   value: 'PostBox',
        // },

        // {
        //   text: 'فکس',
        //   align: 'start',
        //   value: 'Fax',
        // },
        // {
        //   text: 'آدرس',
        //   align: 'start',
        //   value: 'Address',
        // },

        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getblogList", "getBlogcategoryList", "getUrl"]),
  },
  created() {
    this.getContentList();
    this.getblogcategoryList();
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "blog/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      this.$store.dispatch("sendList", {
        url: "blog/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    remove() {
      // this.editData.Cover = '';
      // this.$refs.image.src = '';
      this.$refs.image.src = "";
    },
    getContentList() {
      this.$store.dispatch("sendList", {
        url: "blog/list/",
        page: 1,
        Phrase: "",
      });
    },
    getblogcategoryList() {
      this.$store.dispatch("sendList", {
        url: "blogcategory/list/",
        page: 1,
        Phrase: "",
      });
    },
    uploadImage(e) {
      // خواندن فایل عکس به صورت باینری

      const fileInput = this.$refs.fileInput.$refs.input;
      // console.log(e);
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      // تبدیل رشته عکس به رشته Base64
      reader.onload = () => {
        const base64Image = btoa(reader.result);

        // تعیین بدنه درخواست
        const data = {
          File: base64Image,
          Language: 0,
        };

        // ارسال درخواست به سرور
        axios
          .post(`${this.getUrl}/blog/fileuploader/`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.addData.Cover = response.data.fullPath;
            // console.log("خطا در آپلود عکس:", response);
          })
          .catch((error) => {
            console.error("خطا در آپلود عکس:", error.message);
          });
      };
    },
    uploadEditImage(e) {
      // خواندن فایل عکس به صورت باینری

      const fileInput = this.$refs.fileInput.$refs.input;
      // console.log(e);
      const file = fileInput.files[0];
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      // تبدیل رشته عکس به رشته Base64
      reader.onload = () => {
        const base64Image = btoa(reader.result);

        // تعیین بدنه درخواست
        const data = {
          File: base64Image,
          Language: 0,
        };

        // ارسال درخواست به سرور
        axios
          .post(`${this.getUrl}/blog/fileuploader/`, data, {
            headers: {
              "Content-Type": "application/json",
            },
          })
          .then((response) => {
            this.editData.data.cover = response.data.fullPath;
            // console.log("خطا در آپلود عکس:", response);
          })
          .catch((error) => {
            console.error("خطا در آپلود عکس:", error.message);
          });
      };
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/blog/get/`, {
          params: {
            Id: item.blogId,
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response, "==============");
          this.select = [];
          this.content = [];
          this.editData.data = response.data.record;
          this.editData.data.relatedBlog = [];
          this.content = response.data.relatedBlogs;
          this.select = response.data.tags;
          this.editData.data.tags = [];
          this.editData.data.test = this.$refs;
          for (const item of this.content) {
            this.editData.data.relatedBlog.push(item.BlogId);
          }
          //  console.log(this.editData.data.RelatedBlog);
          for (const item of this.select) {
            // console.log(item.Phrase , 'item');
            this.editData.data.tags.push(item.phrase);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  watch: {},
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
