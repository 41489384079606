export default {
  setCustomer: (state, customer) => {
    state.customers.custmer = customer;
  },
  setCustomerLoading: (state, loading) => {
    state.customers.customerLoading = loading;
  }, 
  setCustomersList: (state, customers) => {
    state.customers.custmers = customers;
  },
  setCustomersListLoading: (state, loading) => {
    state.customers.customersListLoading = loading;
  },
};
