<template>
  <v-card class="rounded-lg">
    <v-card-actions v-if="!download">
      <v-btn class="mr-2 success" @click="addForm = true">
        <v-icon small> mdi-plus </v-icon>
        افزودن
      </v-btn>
    </v-card-actions>
    <v-card-title>
      <v-text-field
        v-model="search1"
        outlined
        dense
        append-icon="mdi-magnify"
        label="جستجو"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-card-text>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="addForm"
      >
        <v-card>
          <v-card-title-close @close="addForm = false">
            ایجاد
          </v-card-title-close>
          <slot name="addForm"></slot>
          <v-card-actions class="justify-center">
            <v-btn v-on:click="addItem()" class="success">افزودن</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="600"
        v-model="editForm"
      >
        <v-card>
          <v-card-title-close @close="editForm = false">
            ویرایش
          </v-card-title-close>
          <slot name="editForm"></slot>
          <v-card-actions class="justify-center">
            <v-btn v-on:click="editItem()" class="success">ویرایش</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      <v-dialog
        transition="dialog-bottom-transition"
        max-width="800"
        v-model="addFormAttachment"
      >
        <v-card>
          <v-card-title-close @close="addFormAttachment = false">
            ایجاد
          </v-card-title-close>
          <v-card-actions class="justify-center">
            <v-btn v-on:click="addItem()">انتخاب فایل</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-data-table
        :headers="headers"
        :items="sendList"
        :search="search1"
        :items-per-page="5"
      >
        <template v-slot:[`item.createdDate`]="{ item }">
          <span>{{ toPersianDateTime(item.createdDate) }}</span>
        </template>
        <template v-slot:[`item.deliveryDate`]="{ item }">
          <span>{{ toPersianDate(item.deliveryDate) }}</span>
        </template>
        <template v-slot:[`item.duedate`]="{ item }">
          <span>{{ toPersianDate(item.duedate) }}</span>
        </template>
        <template v-slot:[`item.activityDate`]="{ item }">
          <span>{{ toPersianDate(item.activityDate) }}</span>
        </template>
        <template v-slot:[`item.creatorUserId`]="{ item }">
          <span dense>{{ getUserFullName(item.creatorUserId) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom v-if="edit">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                dense
                class="ma-1"
                v-bind="attrs"
                v-on="on"
                @click="openEditForm(item)"
              >
                mdi-pencil
              </v-icon>
            </template>
            <span>ویرایش</span>
          </v-tooltip>
          <v-tooltip bottom v-if="download">
            <template v-slot:activator="{ on, attrs }">
              <a
                :href="
                  downloadUrl +
                  '?id=' +
                  item.documentAttachmentId +
                  '&token=' +
                  token
                "
              >
                <v-icon> mdi-download </v-icon>
              </a>
            </template>
            <span>دانلود</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-bind="attrs"
                v-on="on"
                dense
                class="ma-1"
                @click="deleteItem(item)"
                color="error"
              >
                mdi-delete
              </v-icon>
            </template>
            <span>حذف</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
  
  <script>
import { mapGetters } from "vuex";
import VCardTitleClose from "./VCardTitleClose";
import { myMixin } from "../../mixins.js";

export default {
  mixins: [myMixin],
  name: "AccountingSystem",
  components: {
    VCardTitleClose,
  },
  computed: {
    ...mapGetters(["getPersonList"]),
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      addForm: false,
      editForm: false,
      addFormAttachment: false,
      editData: null,
      search1: "",
    };
  },
  props: [
    "headers",
    "sendList",
    "sendaddData",
    "sendEditData",
    "sendremoveData",
    "plus",
    "download",
    "downloadUrl",
    "edit",
  ],
  created() {
    this.getPerson();
  },
  methods: {
    getPerson() {
      this.$store.dispatch("sendList", { url: "person/list/", page: 1 });
    },
    getUserFullName(id) {
      debugger;
      let result = this.getPersonList.records.find((item) => {
        return item.id == id;
      });
      if (result) return `${result.firstName} ${result.lastName}`;
    },
    addItem() {
      this.$store.dispatch("sendNewitem", { data: this.sendaddData });
      this.addForm = false;
    },
    openaddForm() {
      this.addForm = true;
    },
    openEditForm(item) {
      this.editForm = true;
      this.$emit("sendData", item);
    },
    downloadItem(item) {
      console.log(item);
      this.$emit("sendDataDownloadAttachment", item);
      if (item.CustomerAttachmentId) {
        return `${this.getUrl}/message/download/?attachment_id=${item.CustomerAttachmentId}`;
      } else if (item.ContractAttachmentId) {
        return `${this.getUrl}/message/download/?attachment_id=${item.ContractAttachmentId}`;
      } else if (item.DocumentAttachmentId) {
        return `${this.getUrl}/message/download/?attachment_id=${item.DocumentAttachmentId}`;
      }
    },
    editItem() {
      this.$store.dispatch("sendEditItem", {
        data: this.sendEditData,
        id: this.editData,
      });
      this.editForm = false;
    },
    deleteItem(item) {
      this.$emit("sendDataRemoveAttachment", item);
    },
  },
};
</script>
  
  <style>
</style>