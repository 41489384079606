import axios from "axios";
import Vue from "vue";
import router from "../router/index.js";
import moment from "jalali-moment";

export default {
  setToken(context, payload) {
    if (payload) {
      localStorage.setItem("token", payload);
      context.commit("setToken", payload);
      Vue.socket.start({
        token: payload,
        withCredentials: false,
        log: true, // Logging is optional but very helpful during development
      });
    }
  },
  logout(context /*, payload*/) {
    localStorage.removeItem("token");
    context.commit("setToken", null);
    router
      .push({
        name: "Login",
      })
      .catch(() => {});
    Vue.socket.stop();
  },
  sendList(context, payload) {
    context.commit("changeLoading", true);
    axios
      .get(`${context.getters.getUrl}/${payload.url}`, {
        params: {
          Count: payload.count ? payload.count : 20,
          Page: payload.page,
          Phrase: payload.phrase,
          Language: 0,
        },
      })
      .then((data) => {
        if (payload.url == "bank/list/")
          context.commit("setBankList", data.data);
        else if (payload.url == "onlineuser/list/")
          context.commit("setOnlineuser", data.data);
        else if (payload.url == "ece/list/")
          context.commit("setEce", data.data);
        else if (payload.url == "ecereceivers/list/")
          context.commit("setEceReceivers", data.data);
        else if (payload.url == "eceemails/list/")
          context.commit("seteceemails", data.data);
        else if (payload.url == "account/list/")
          context.commit("setAccounts", data.data);
        else if (payload.url == "usergroupmembers/list/")
          context.commit("setusergroupmembers", data.data);
        else if (payload.url == "domain/list/")
          context.commit("setDomainList", data.data);
        else if (payload.url == "bankaccount/list/")
          context.commit("setBankAccount", data.data);
        else if (payload.url == "cashdesk/list/")
          context.commit("setFundsList", data.data);
        else if (payload.url == "andikator/list/")
          context.commit("setAndikatorsList", data.data);
        else if (payload.url == "reciveMethod/list/")
          context.commit("setReciveMethodList", data.data);
        else if (payload.url == "customergroup/list/")
          context.commit("setcustomergroupList", data.data);
        else if (payload.url == "orgpost/list/")
          context.commit("setorgpostList", data.data);
        else if (payload.url == "orgunit/list/")
          context.commit("setorgunitList", data.data);
        else if (payload.url == "company/list/")
          context.commit("setcompanyList", data.data);
        else if (payload.url == "blog/list/")
          context.commit("setblogList", data.data);
        else if (payload.url == "blogcategory/list/")
          context.commit("setBlogcategoryList", data.data);
        else if (payload.url == "project/list/")
          context.commit("setProjectList", data.data);
        else if (payload.url == "projectgroup/list/")
          context.commit("setProjectgroup", data.data);
        else if (payload.url == "contract/list/")
          context.commit("setContractList", data.data);
        else if (payload.url == "contractgroup/list/")
          context.commit("setContractgroupList", data.data);
        else if (payload.url == "document/list/")
          context.commit("setDocumentList", data.data);
        else if (payload.url == "person/list/")
          context.commit("setPersonList", data.data);
        else if (payload.url == "customer/list/")
          context.commit("setCustomerList", data.data);
        else if (payload.url == "invoice/list/")
          context.commit("setInvoiceList", data.data);
        else if (payload.url == "ticket/list/")
          context.commit("setTicketList", data.data);
        else if (payload.url == "plan/list/")
          context.commit("setPlanList", data.data);
        else if (payload.url == "archive/list/")
          context.commit("setArchiveList", data.data);
        else if (payload.url == "favorite/list/")
          context.commit("setFavoriteList", data.data);
        else if (payload.url == "pageaction/list/")
          context.commit("setPageactionList", data.data);
        else if (payload.url == "system/list/")
          context.commit("setSystemList", data.data);
        else if (payload.url == "usergroupmember/list/")
          context.commit("setUsergroupmemberList", data.data);
        else if (payload.url == "usergroup/list/")
          context.commit("setUsergroupList", data.data);
        else if (payload.url == "template/list/")
          context.commit("setTemplateList", data.data);
        else if (payload.url == "link/list/")
          context.commit("setLinks", data.data);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        context.commit("changeLoading", false);
      });
  },
  sendListContractPayment(context, payload) {
    axios
      .get(`${context.getters.getUrl}/${payload.url}`, {
        params: {
          Count: 1000,
          Page: 1,
          Phrase: payload.phrase,
          ContractFk: payload.id,
          Language: 0,
        },
      })
      .then((data) => {
        context.commit("setContractpaymentList", data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  sendListMenu(context, payload) {
    axios
      .get(`${context.getters.getUrl}/${payload.url}`, {
        params: {
          Language: 0,
        },
      })
      .then((data) => {
        if (data.data.status == 1) {
          let name = localStorage.getItem("tenantFullName")
            ? localStorage.getItem("tenantFullName")
            : "داشبورد";
          data.data.records.unshift({ children: [], title: name });
          context.commit("setMenu", data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
  sendListContractSupplement(context, payload) {
    axios
      .get(`${context.getters.getUrl}/${payload.url}`, {
        params: {
          Count: 1000,
          Page: 1,
          ContractFk: payload.id,
          Language: 0,
        },
      })
      .then((data) => {
        context.commit("setContractSupplement", data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  sendListContractAction(context, payload) {
    axios
      .get(`${context.getters.getUrl}/${payload.url}`, {
        params: {
          Count: 100,
          Page: 1,
          ContractFk: payload.id,
          Language: 0,
        },
      })
      .then((data) => {
        context.commit("setContractAction", data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  sendListCustomerContact(context, payload) {
    axios
      .get(`${context.getters.getUrl}/${payload.url}`, {
        params: {
          Count: 100,
          Page: 1,
          CustomerFk: payload.id,
          Language: 0,
        },
      })
      .then((data) => {
        context.commit("setCustomerContact", data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  sendListFavoritePersons(context, payload) {
    axios
      .get(`${context.getters.getUrl}/${payload.url}`, {
        params: {
          Language: 0,
        },
      })
      .then((data) => {
        context.commit("setFavoritePersons", data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },

  sendNewitem({ dispatch, commit, state, getters }, payload) {
    commit("changeLoading", true);

    let data = {};
    if (payload.data.url == "bank/create/")
      data = {
        Title: payload.data.title,
      };
    else if (payload.data.url == "ecereceivers/create/")
      data = {
        CustomerId: payload.data.customerId,
      };
    else if (payload.data.url == "ece/create/")
      data = {
        AndikatorFk: payload.data.andikatorFk,
        ECEEmailFk: payload.data.eCEEmailFk,
        In: payload.data.in,
        OrgPostFk: payload.data.orgPostFk,
        Out: payload.data.out,
        Title: payload.data.title,
      };
    else if (payload.data.url == "eceemails/create/")
      data = {
        InputPort: payload.data.inputPort,
        InputServer: payload.data.inputServer,
        OutputPort: payload.data.outputPort,
        OutputServer: payload.data.outputServer,
        Title: payload.data.title,
        UserName: payload.data.userName,
        Email: payload.data.email,
        Password: payload.data.password,
        InSSl: payload.data.inSSl,
        OutSSl: payload.data.outSSl,
        OutTls: payload.data.outTls,
      };
    else if (payload.data.url == "account/create/")
      data = {
        Title: payload.data.title,
        ParentFk: payload.data.parentFk,
      };
    else if (payload.data.url == "bankaccount/create/")
      data = {
        Number: payload.data.number,
        BankFk: payload.data.bankFk,
      };
    else if (payload.data.url == "cashdesk/create/")
      data = {
        Title: payload.data.title,
      };
    else if (payload.data.url == "andikator/create/")
      data = {
        AndikatorPreFix: payload.data.prefix,
        AndikatorTitle: payload.data.title,
        IsActive: payload.data.active,
        IsDefault: payload.data.default,
        StartNumber: payload.data.startNumber,
        MessageTypeFk: payload.data.messageTypeFk,
      };
    else if (payload.data.url == "orgpost/create/")
      data = {
        OrgPostTitle: payload.data.orgPostTitle,
        OrgUnitFk: payload.data.orgUnitFk,
        IsActive: payload.data.isActive,
      };
    else if (payload.data.url == "orgunit/create/")
      data = {
        OrgUnitTitle: payload.data.orgUnitTitle,
        ParentOrgUnitFk: payload.data.parentOrgUnitFk,
        IsActive: payload.data.isActive,
      };
    else if (payload.data.url == "company/create/")
      data = {
        EconomicalCode: payload.data.economicalCode,
        NationalId: payload.data.nationalId,
        CompanyName: payload.data.companyName,
        ParentCompanyFk: payload.data.parentCompanyFk,
        Email: payload.data.email,
        Phone: payload.data.phone,
        Mobile: payload.data.mobile,
        Fax: payload.data.fax,
        PostalCode: payload.data.postalCode,
        Address: payload.data.address,
        IsActive: payload.data.isActive,
      };
    else if (payload.data.url == "blog/create/")
      data = {
        PageName: payload.data.pageName,
        BlogCategoryFk: payload.data.blogCategoryFk,
        Cover: payload.data.cover,
        Description: payload.data.description,
        Lead: payload.data.lead,
        MetaDescription: payload.data.metaDescription,
        MetaKeyword: payload.data.metaKeyword,
        MetaTitle: payload.data.metaTitle,
        Title: payload.data.title,
        RelatedBlog: payload.data.relatedBlog,
        Tags: payload.data.tags.toString(),
      };
    else if (payload.data.url == "blogcategory/create/")
      data = {
        Title: payload.data.title,
      };
    else if (payload.data.url == "project/create/")
      data = {
        ProjectTitle: payload.data.projectTitle,
        Cover: payload.data.cover,
        FinishDate: moment
          .from(payload.data.finishDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        StartDate: moment
          .from(payload.data.startDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        ProjectGroupFk: payload.data.projectGroupFk,
        Tags: payload.data.tags.toString(),
      };
    else if (payload.data.url == "projectgroup/create/")
      data = {
        ProjectGroupTitle: payload.data.projectGroupTitle,
      };
    else if (payload.data.url == "contractpayment/create/")
      data = {
        ContractFk: payload.data.contractFk,
        GuaranteeTitle: payload.data.guaranteeTitle,
        PaymentMethodFk: payload.data.paymentMethodFk,
        VchNumber: payload.data.vchNumber,
        Duedate: moment
          .from(payload.data.duedate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        DeliveryDate: moment
          .from(payload.data.deliveryDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        Price: payload.data.price,
        Comment: payload.data.comment,
        PaymentType: payload.data.paymentType,
      };
    else if (payload.data.url == "contractcoefficient/create/")
      data = {
        Price: payload.data.price,
        Percentage: payload.data.percentage,
        Comment: payload.data.comment,
        CoefficientsFk: payload.data.coefficientsFk,
        ContractFk: payload.data.contractFk,
      };
    else if (payload.data.url == "contract/create/")
      data = {
        ContractGroupFk: payload.data.contractGroupFk,
        UserId: payload.data.userId,
        CustomerFk: payload.data.customerFk,
        ContractNotation: payload.data.contractNotation,
        ContractNumber: payload.data.contractNumber,
        ContractTitle: payload.data.contractTitle,
        StartDate: moment
          .from(payload.data.startDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        EndDate: moment
          .from(payload.data.endDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        IsActive: payload.data.isActive,
        ProjectFk: payload.data.projectFk,
        ContractType: payload.data.contractType,
        contractMainPrice: payload.data.contractMainPrice,
        //Tags: payload.data.contractTags.toString(),
      };
    else if (payload.data.url == "contractgroup/create/")
      data = {
        ContractGroupTitle: payload.data.contractGroupTitle,
        IsCost: payload.data.isCost,
      };
    else if (payload.data.url == "document/create/")
      data = {
        DocumentType: payload.data.documentType,
        AccountType: payload.data.accountType,
        BankAccountFk: payload.data.bankAccountFk,
        CashDeskFk: payload.data.cashDeskFk,
        Date: moment
          .from(payload.data.date, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        Price: payload.data.price,
        Description: payload.data.description,
        ProjectFk: payload.data.projectFk,
        ContractFk: payload.data.contractFk,
        CustomerFk: payload.data.customerFk,
        userId: payload.data.userId,
        AccountFk: payload.data.accountFk,
      };
    else if (payload.data.url == "invoice/create/")
      data = {
        CustomerFk: payload.data.customerFk,
        Date: moment
          .from(payload.data.date, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        InvoiceType: payload.data.invoiceType,
        Number: payload.data.number,
        Items: payload.data.invoiceItems,
        Title: payload.data.title,
      };
    else if (payload.data.url == "customer/create/")
      data = {
        Name: payload.data.name,
        Code: payload.data.code,
        Tell: payload.data.tell,
        Mobile: payload.data.mobile,
        WhatsApp: payload.data.whatsApp,
        Email: payload.data.email,
        Description: payload.data.description,
        CreatedDate: moment
          .from(payload.data.createdDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        CreditDate: moment
          .from(payload.data.creditDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        Tags: payload.data.tags,
        CustomerGroups: payload.data.customerGroups,
        Agents: payload.data.agents,
      };
    else if (payload.data.url == "customergroup/create/")
      data = {
        CustomerGroupTitle: payload.data.customerGroupTitle,
      };
    else if (payload.data.url == "ticket/create/")
      data = {
        OrganUnit: payload.data.organUnit,
        Subject: payload.data.subject,
        Cover: payload.data.cover,
        Message: payload.data.message,
      };
    else if (payload.data.url == "person/create/")
      data = {
        Username: payload.data.userName,
        Address: payload.data.address,
        PreTitle: payload.data.preTitle,
        FirstName: payload.data.firstName,
        LastName: payload.data.lastName,
        FatherName: payload.data.fatherName,
        BirthDate: payload.data.birthDate,
        NationalCode: payload.data.nationalCode,
        ParentCompanyFk: payload.data.parentCompanyFk,
        ProvinceFk: payload.data.provinceFk,
        CityFk: payload.data.cityFk,
        GenderFk: payload.data.genderFk,
        PostalCode: payload.data.postalCode,
        Email: payload.data.email,
        Phone: payload.data.phone,
        Mobile: payload.data.mobile,
        WhatsApp: payload.data.whatsApp,
        Fax: payload.data.fax,
        IsActive: payload.data.isActive,
        UserTypeFk: payload.data.userTypeFk,
        PassWord: payload.data.passWord,
        OrganPosts: payload.data.organPost,
        RoleNames: payload.data.roleNames,
      };
    else if (payload.data.url == "domain/create/")
      data = {
        DomainAddress: payload.data.domainAddress,
        SubDomain: payload.data.subDomain,
        SSLRedirect: payload.data.sSLRedirect,
        WWWRedirect: payload.data.wWWRedirect,
        CompanyName: payload.data.companyName,
        WebmasterFirstname: payload.data.webmasterFirstname,
        WebmasterLastName: payload.data.webmasterLastName,
        ExternalDomain: payload.data.externalDomain,
        WebmasterEmail: payload.data.webmasterEmail,
        WebmasterMobileInfo: payload.data.webmasterMobileInfo,
        LicenseToSendMail: payload.data.licenseToSendMail,
        IsActive: payload.data.isActive,
      };
    else if (payload.data.url == "plan/create/")
      data = {
        IsDefault: payload.data.isDefault,
        MaxHDDUsage: payload.data.maxHDDUsage,
        Title: payload.data.title,
        Duration: payload.data.duration,
        BasePrice: payload.data.basePrice,
        MaxHDDUsageInOwnDomain: payload.data.maxHDDUsageInOwnDomain,
      };
    else if (payload.data.url == "contractactivity/create/")
      data = {
        ActivityTypeFk: payload.data.activityTypeFk,
        ActivityDate: moment
          .from(payload.data.activityDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        ContractFk: payload.data.contractFk,
      };
    else if (payload.data.url == "customercontact/create/")
      data = {
        Title: payload.data.title,
        Date: moment
          .from(payload.data.date, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        CustomerFk: payload.data.customerFk,
      };
    else if (payload.data.url == "archive/create/")
      data = {
        ArchiveTitle: payload.data.archiveTitle,
        FullName: payload.data.archiveTitle,
        ParentFk: payload.data.parentFk,
      };
    else if (payload.data.url == "favorite/create/")
      data = {
        FavoriteTitle: payload.data.favoriteTitle,
        FullName: payload.data.favoriteTitle,
        ParentFk: payload.data.parentFk,
      };
    else if (payload.data.url == "usergroup/create/")
      data = {
        UserGroupTitle: payload.data.userGroupTitle,
        SystemFk: payload.data.systemFk,
        IsActive: payload.data.isActive,
        UserType2: payload.data.userType2,
        UserType3: payload.data.userType3,
        UserGroupMembers: payload.data.userGroupMembers,
        PageActions: payload.data.pageActions,
      };
    else if (payload.data.url == "template/create/")
      data = {
        TemplateName: payload.data.templateName,
        SpecialDomainFk: payload.data.specialDomainFk,
        IsPublic: payload.data.isPublic,
        File: payload.data.file,
        FileName: payload.data.fileName,
      };
    else if (payload.data.url == "link/create/")
      data = {
        Caption: payload.data.title,
        Url: payload.data.link,
      };

    data.Language = 0;
    axios
      .post(`${getters.getUrl}/${payload.data.url}`, data)
      .then((data) => {
        if (data.data.status == 1) {
          commit("setMessage", {
            message: "درخواست با موفقیت انجام شد",
            color: "success",
            timeOut: 2000,
          });

          if (payload.data.url == "bank/create/")
            dispatch("sendList", { url: "bank/list/", page: 1 });
          else if (payload.data.url == "ecereceivers/create/")
            dispatch("sendList", { url: "ecereceivers/list/", page: 1 });
          else if (payload.data.url == "ece/create/")
            dispatch("sendList", { url: "ece/list/", page: 1 });
          else if (payload.data.url == "eceemails/create/")
            dispatch("sendList", { url: "eceemails/list/", page: 1 });
          else if (payload.data.url == "account/create/")
            dispatch("sendList", { url: "account/list/", page: 1 });
          else if (payload.data.url == "bankaccount/create/")
            dispatch("sendList", { url: "bankaccount/list/", page: 1 });
          else if (payload.data.url == "cashdesk/create/")
            dispatch("sendList", { url: "cashdesk/list/", page: 1 });
          else if (payload.data.url == "andikator/create/")
            dispatch("sendList", { url: "andikator/list/", page: 1 });
          else if (payload.data.url == "orgpost/create/")
            dispatch("sendList", { url: "orgpost/list/", page: 1 });
          else if (payload.data.url == "orgunit/create/")
            dispatch("sendList", { url: "orgunit/list/", page: 1 });
          else if (payload.data.url == "company/create/")
            dispatch("sendList", { url: "company/list/", page: 1 });
          else if (payload.data.url == "blog/create/")
            dispatch("sendList", { url: "blog/list/", page: 1 });
          else if (payload.data.url == "blogcategory/create/")
            dispatch("sendList", { url: "blogcategory/list/", page: 1 });
          else if (payload.data.url == "project/create/")
            dispatch("sendList", { url: "project/list/", page: 1 });
          else if (payload.data.url == "projectgroup/create/")
            dispatch("sendList", { url: "projectgroup/list/", page: 1 });
          else if (payload.data.url == "contractpayment/create/")
            dispatch("sendListContractPayment", {
              url: "contractpayment/list/",
              id: payload.data.contractFk,
            });
          else if (payload.data.url == "contractcoefficient/create/")
            dispatch("sendListContractSupplement", {
              url: "contractcoefficient/list/",
              id: payload.data.contractFk,
            });
          else if (payload.data.url == "contract/create/")
            dispatch("sendList", { url: "contract/list/", page: 1 });
          else if (payload.data.url == "contractgroup/create/")
            dispatch("sendList", { url: "contractgroup/list/", page: 1 });
          else if (payload.data.url == "document/create/")
            dispatch("sendList", { url: "document/list/", page: 1 });
          else if (payload.data.url == "invoice/create/")
            dispatch("sendList", { url: "invoice/list/", page: 1 });
          else if (payload.data.url == "customer/create/")
            dispatch("sendList", { url: "customer/list/", page: 1 });
          else if (payload.data.url == "customergroup/create/")
            dispatch("sendList", { url: "customergroup/list/", page: 1 });
          else if (payload.data.url == "ticket/create/")
            dispatch("sendList", { url: "ticket/list/", page: 1 });
          else if (payload.data.url == "person/create/")
            dispatch("sendList", { url: "person/list/", page: 1 });
          else if (payload.data.url == "domain/create/")
            dispatch("sendList", { url: "domain/list/", page: 1 });
          else if (payload.data.url == "plan/create/")
            dispatch("sendList", { url: "plan/list/", page: 1 });
          else if (payload.data.url == "contractactivity/create/")
            dispatch("sendListContractAction", {
              url: "contractactivity/list/",
              id: payload.data.contractFk,
            });
          else if (payload.data.url == "customercontact/create/")
            dispatch("sendListCustomerContact", {
              url: "customercontact/list/",
              id: payload.data.customerFk,
            });
          else if (payload.data.url == "archive/create/")
            dispatch("sendList", { url: "archive/list/", page: 1 });
          else if (payload.data.url == "favorite/create/")
            dispatch("sendList", { url: "favorite/list/", page: 1 });
          else if (payload.data.url == "usergroup/create/")
            dispatch("sendList", { url: "usergroup/list/", page: 1 });
          else if (payload.data.url == "template/create/")
            dispatch("sendList", { url: "template/list/", page: 1 });
          else if (payload.data.url == "link/create/")
            dispatch("sendList", { url: "link/list/", page: 1 });
        } else if (data.data.status == 0) {
          commit("setMessage", {
            message: data.data.message,
            color: "red",
            timeOut: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit("changeLoading", false);
      });
  },

  sendEditItem({ dispatch, commit, state, getters }, payload) {
    commit("changeLoading", true);

    if (payload.data.data.Tags)
      payload.data.data.Tags = payload.data.data.Tags.toString();
    if (payload.data.data.ProjectTags)
      payload.data.data.ProjectTags = payload.data.data.ProjectTags.toString();
    if (payload.data.Tags)
      payload.data.Tags = JSON.stringify(payload.data.Tags);

    let data = payload?.data?.data;

    if (payload.data.url == "bank/update/")
      data = {
        Id: payload.data.data.bankId,
        Title: payload.data.data.title,
      };
    else if (payload.data.url == "eceemails/update/")
      data = {
        Id: payload.data.data.eCEEmailId,
        InputPort: payload.data.data.inputPort,
        InputServer: payload.data.data.inputServer,
        OutputPort: payload.data.data.outputPort,
        OutputServer: payload.data.data.outputServer,
        Title: payload.data.data.title,
        UserName: payload.data.data.userName,
        Email: payload.data.data.email,
        Password: payload.data.data.password,
        InSSl: payload.data.data.inSSl,
        OutSSl: payload.data.data.outSSl,
        OutTls: payload.data.data.outTls,
      };
    else if (payload.data.url == "link/update/")
      data = {
        Id: payload.data.data.linkId,
        Caption: payload.data.data.caption,
        Url: payload.data.data.url,
      };
    else if (payload.data.url == "ece/update/")
      data = {
        Id: payload.data.data.eCEId,
        AndikatorFk: payload.data.data.andikatorFk,
        ECEEmailFk: payload.data.data.eCEEmailFk,
        In: payload.data.data.in,
        OrgPostFk: payload.data.data.orgPostFk,
        Out: payload.data.data.out,
        Title: payload.data.data.title,
      };
    else if (payload.data.url == "account/update/")
      data = {
        Id: payload.data.data.accountId,
        Title: payload.data.data.title,
        ParentFk: payload.data.data.parentFk,
      };
    else if (payload.data.url == "bankaccount/update/")
      data = {
        Id: payload.data.data.bankAccountId,
        Number: payload.data.data.number,
        BankFk: payload.data.data.bankFk,
      };
    else if (payload.data.url == "cashdesk/update/")
      data = {
        Id: payload.data.data.cashDeskId,
        Title: payload.data.data.title,
      };
    else if (payload.data.url == "andikator/update/")
      data = {
        Id: payload.data.data.andikatorId,
        AndikatorPreFix: payload.data.data.andikatorPreFix,
        AndikatorTitle: payload.data.data.andikatorTitle,
        IsActive: payload.data.data.isActive,
        IsDefault: payload.data.data.isDefault,
        StartNumber: payload.data.data.startNumber,
        MessageTypeFk: payload.data.data.messageTypeFk,
      };
    else if (payload.data.url == "orgpost/update/")
      data = {
        Id: payload.data.data.orgPostId,
        OrgPostTitle: payload.data.data.orgPostTitle,
        OrgUnitFk: payload.data.data.orgUnitFk,
        IsActive: payload.data.data.isActive,
      };
    else if (payload.data.url == "orgunit/update/")
      data = {
        Id: payload.data.data.orgUnitId,
        OrgUnitTitle: payload.data.data.orgUnitTitle,
        ParentOrgUnitFk: payload.data.data.parentOrgUnitFk,
        IsActive: payload.data.data.isActive,
      };
    else if (payload.data.url == "company/update/")
      data = {
        Id: payload.data.data.companyId, // مشکل داره میگه یه ایتم ارسال نمیشه ولی همه ارسل میشه
        EconomicalCode: payload.data.data.economicalCode,
        NationalId: payload.data.data.nationalId,
        CompanyName: payload.data.data.companyName,
        ParentCompanyFk: payload.data.data.parentCompanyFk,
        Email: payload.data.data.email,
        Phone: payload.data.data.phone,
        Mobile: payload.data.data.mobile,
        Fax: payload.data.data.fax,
        PostalCode: payload.data.data.postalCode,
        Address: payload.data.data.address,
        IsActive: payload.data.data.isActive,
      };
    else if (payload.data.url == "blogcategory/update/")
      data = {
        Id: payload.data.data.blogCategoryId,
        Title: payload.data.data.title,
      };
    else if (payload.data.url == "blog/update/")
      data = {
        Id: payload.data.data.blogId,
        PageName: payload.data.data.pageName,
        BlogCategoryFk: payload.data.data.blogCategoryFk,
        Cover: payload.data.data.cover,
        Description: payload.data.data.description,
        Lead: payload.data.data.lead,
        MetaDescription: payload.data.data.metaDescription,
        MetaKeyword: payload.data.data.metaKeyword,
        MetaTitle: payload.data.data.metaTitle,
        Title: payload.data.data.title,
        RelatedBlog: payload.data.data.relatedBlog,
        Tags: payload.data.data.tags.toString(),
      };
    else if (payload.data.url == "blogcategory/update/")
      data = {
        Id: payload.data.data.blogCategoryId,
        Title: payload.data.title,
      };
    else if (payload.data.url == "project/update/")
      data = {
        Id: payload.data.data.projectId,
        ProjectTitle: payload.data.data.projectTitle,
        Cover: payload.data.data.cover,
        FinishDate: moment
          .from(payload.data.data.finishDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        StartDate: moment
          .from(payload.data.data.startDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        ProjectGroupFk: payload.data.data.projectGroupFk,
        Tags: payload.data.data.projectTags.toString(),
      };
    else if (payload.data.url == "projectgroup/update/")
      data = {
        Id: payload.data.data.projectGroupId,
        ProjectGroupTitle: payload.data.data.projectGroupTitle,
      };
    else if (payload.data.url == "contract/update/")
      data = {
        Id: payload.data.data.contractId,
        ContractGroupFk: payload.data.data.contractGroupFk,
        UserId: payload.data.data.userId,
        CustomerFk: payload.data.data.customerFk,
        ContractNotation: payload.data.data.contractNotation,
        ContractNumber: payload.data.data.contractNumber,
        ContractTitle: payload.data.data.contractTitle,
        StartDate: moment
          .from(payload.data.data.startDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        EndDate: moment
          .from(payload.data.data.endDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        IsActive: payload.data.data.isActive,
        ProjectFk: payload.data.data.projectFk,
        ContractType: payload.data.data.contractType,
        ContractMainPrice: payload.data.data.contractMainPrice,
        //Tags: payload.data.data.contractTags.toString(),
      };
    else if (payload.data.url == "contractgroup/update/")
      data = {
        Id: payload.data.data.contractGroupId,
        ContractGroupTitle: payload.data.data.contractGroupTitle,
        IsCost: payload.data.data.isCost,
      };
    else if (payload.data.url == "document/update/")
      data = {
        Id: payload.data.data.documentId,
        DocumentType: payload.data.data.documentType,
        AccountType: payload.data.data.accountType,
        BankAccountFk: payload.data.data.bankAccountFk,
        CashDeskFk: payload.data.data.cashDeskFk,
        Date: moment
          .from(payload.data.data.date, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        Price: payload.data.data.price,
        Description: payload.data.data.description,
        ProjectFk: payload.data.data.projectFk,
        ContractFk: payload.data.data.contractFk,
        CustomerFk: payload.data.data.customerFk,
        userId: payload.data.data.userId,
        AccountFk: payload.data.data.accountFk,
      };
    else if (payload.data.url == "invoice/update/")
      data = {
        Id: payload.data.data.invoiceId,
        CustomerFk: payload.data.data.customerFk,
        Date: moment
          .from(payload.data.data.date, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        InvoiceType: payload.data.data.invoiceType,
        Number: payload.data.data.number,
        Title: payload.data.data.title,
        Items: payload.data.data.invoiceItems,
      };
    else if (payload.data.url == "customer/update/")
      data = {
        Id: payload.data.data.customerId,
        Name: payload.data.data.name,
        Code: payload.data.data.code,
        Tell: payload.data.data.tell,
        Mobile: payload.data.data.mobile,
        WhatsApp: payload.data.data.whatsApp,
        Email: payload.data.data.email,
        Description: payload.data.data.description,
        CreatedDate: moment
          .from(payload.data.data.createdDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        CreditDate: moment
          .from(payload.data.data.creditDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        Tags: payload.data.data.tags,
        CustomerGroups: payload.data.data.customerGroups.map(function (item) {
          return item.customerGroupId;
        }),
        Agents: payload.data.data.customerAgents,
      };
    else if (payload.data.url == "customergroup/update/")
      data = {
        Id: payload.data.data.customerGroupId,
        CustomerGroupTitle: payload.data.data.customerGroupTitle,
      };
    else if (payload.data.url == "ticket/update/")
      data = {
        Id: payload.data.data.ticketId,
        OrganUnit: payload.data.data.organUnit,
        Subject: payload.data.data.subject,
        Cover: payload.data.data.cover,
        Message: payload.data.message,
      };
    else if (payload.data.url == "person/update/")
      data = {
        Id: payload.data.data.id,
        Username: payload.data.data.userName,
        Address: payload.data.data.address,
        PreTitle: payload.data.data.preTitle,
        FirstName: payload.data.data.firstName,
        LastName: payload.data.data.lastName,
        FatherName: payload.data.data.fatherName,
        BirthDate: payload.data.data.birthDate,
        NationalCode: payload.data.data.nationalCode,
        ParentCompanyFk: payload.data.data.parentCompanyFk,
        ProvinceFk: payload.data.data.provinceFk,
        CityFk: payload.data.data.cityFk,
        GenderFk: payload.data.data.genderFk,
        PostalCode: payload.data.data.postalCode,
        Email: payload.data.data.email,
        Phone: payload.data.data.phone,
        Mobile: payload.data.data.mobile,
        WhatsApp: payload.data.data.whatsApp,
        Fax: payload.data.data.fax,
        IsActive: payload.data.data.isActive,
        UserTypeFk: payload.data.data.userTypeFk,
        PassWord: payload.data.data.passWord,
        OrganPosts: payload.data.data.organPost,
        RoleNames: payload.data.data.roleNames,
      };
    else if (payload.data.url == "domain/update/")
      data = {
        DomainId: payload.id.domainId,
        DomainAddress: payload.data.data.domainAddress,
        SubDomain: payload.data.data.subDomain,
        SSLRedirect: payload.data.data.sSLRedirect,
        WWWRedirect: payload.data.data.wWWRedirect,
        CompanyName: payload.data.data.companyName,
        WebmasterFirstname: payload.data.data.webmasterFirstname,
        WebmasterLastName: payload.data.data.webmasterLastName,
        ExternalDomain: payload.data.data.externalDomain,
        WebmasterEmail: payload.data.data.webmasterEmail,
        WebmasterMobileInfo: payload.data.data.webmasterMobileInfo,
        LicenseToSendMail: payload.data.data.licenseToSendMail,
        IsActive: payload.data.data.isActive,
      };
    else if (payload.data.url == "plan/update/")
      data = {
        Id: payload.data.data.planId,
        IsDefault: payload.data.data.isDefault,
        MaxHDDUsage: payload.data.data.maxHDDUsage,
        Title: payload.data.data.title,
        Duration: payload.data.data.duration,
        BasePrice: payload.data.data.basePrice,
        MaxHDDUsageInOwnDomain: payload.data.data.maxHDDUsageInOwnDomain,
      };
    else if (payload.data.url == "contractcoefficient/update/")
      data = {
        Id: payload.data.data.contractCoefficientsId,
        Price: payload.data.data.price,
        Percentage: payload.data.data.percentage,
        Comment: payload.data.data.comment,
        CoefficientsFk: payload.data.data.coefficientsFk,
        ContractFk: payload.data.data.contractFk,
      };
    else if (payload.data.url == "contractactivity/update/")
      data = {
        Id: payload.data.data.contractActivityId,
        ActivityTypeFk: payload.data.data.activityTypeFk,
        ActivityDate: moment
          .from(payload.data.data.activityDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        ContractFk: payload.data.data.contractFk,
      };
    else if (payload.data.url == "customercontact/update/")
      data = {
        Id: payload.data.data.customerContactId,
        Title: payload.data.data.title,
        Date: moment
          .from(payload.data.data.date, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        CustomerFk: payload.data.data.customerFk,
      };
    else if (payload.data.url == "archive/update/")
      data = {
        Id: payload.data.data.archiveId,
        ArchiveTitle: payload.data.data.archiveTitle,
        FullName: payload.data.data.archiveTitle,
        ParentFk: payload.data.data.parentFk,
      };
    else if (payload.data.url == "favorite/update/")
      data = {
        Id: payload.data.data.favoriteId,
        FavoriteTitle: payload.data.data.favoriteTitle,
        FullName: payload.data.data.favoriteTitle,
        ParentFk: payload.data.data.parentFk,
      };
    else if (payload.data.url == "usergroup/update/")
      data = {
        Id: payload.data.data.userGroupId,
        UserGroupTitle: payload.data.data.userGroupTitle,
        SystemFk: payload.data.data.systemFk,
        IsActive: payload.data.data.isActive,
        UserType2: payload.data.data.userType2,
        UserType3: payload.data.data.userType3,
        UserGroupMembers: payload.data.data.userGroupMembers,
        PageActions: payload.data.data.pageActions,
      };
    else if (payload.data.url == "contractpayment/update/")
      data = {
        Id: payload.data.data.contractPaymentId,
        ContractFk: payload.data.data.contractFk,
        GuaranteeTitle: payload.data.data.guaranteeTitle,
        PaymentMethodFk: payload.data.data.paymentMethodFk,
        VchNumber: payload.data.data.vchNumber,
        Duedate: moment
          .from(payload.data.data.duedate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        DeliveryDate: moment
          .from(payload.data.data.deliveryDate, "fa", "YYYY/MM/DD")
          .locale("en")
          .format("YYYY-MM-DDThh:mm:ss"),
        Price: payload.data.data.price,
        Comment: payload.data.data.comment,
        PaymentType: payload.data.data.paymentType,
      };
    else if (payload.data.url == "template/update/")
      data = {
        Id: payload.data.data.templateManagerId,
        TemplateName: payload.data.data.templateName,
        SpecialDomainFk: payload.data.data.specialDomainFk,
        IsPublic: payload.data.data.isPublic,
        File: payload.data.data.file,
        FileName: payload.data.data.fileName,
      };

    data.Language = 0;

    axios
      .put(`${getters.getUrl}/${payload.data.url}`, data)
      .then((data) => {
        if (data.data.status == 1) {
          if (payload.data.url == "bank/update/")
            dispatch("sendList", { url: "bank/list/", page: 1 });
          else if (payload.data.url == "eceemails/update/")
            dispatch("sendList", { url: "eceemails/list/", page: 1 });
          else if (payload.data.url == "link/update/")
            dispatch("sendList", { url: "link/list/", page: 1 });
          else if (payload.data.url == "ece/update/")
            dispatch("sendList", { url: "ece/list/", page: 1 });
          else if (payload.data.url == "account/update/")
            dispatch("sendList", { url: "account/list/", page: 1 });
          else if (payload.data.url == "bankaccount/update/")
            dispatch("sendList", { url: "bankaccount/list/", page: 1 });
          else if (payload.data.url == "cashdesk/update/")
            dispatch("sendList", { url: "cashdesk/list/", page: 1 });
          else if (payload.data.url == "andikator/update/")
            dispatch("sendList", { url: "andikator/list/", page: 1 });
          else if (payload.data.url == "orgpost/update/")
            dispatch("sendList", { url: "orgpost/list/", page: 1 });
          else if (payload.data.url == "orgunit/update/")
            dispatch("sendList", { url: "orgunit/list/", page: 1 });
          else if (payload.data.url == "company/update/")
            dispatch("sendList", { url: "company/list/", page: 1 });
          else if (payload.data.url == "blogcategory/update/")
            dispatch("sendList", { url: "blogcategory/list/", page: 1 });
          else if (payload.data.url == "blog/update/")
            dispatch("sendList", { url: "blog/list/", page: 1 });
          else if (payload.data.url == "blogcategory/update/")
            dispatch("sendList", { url: "blogcategory/list/", page: 1 });
          else if (payload.data.url == "project/update/")
            dispatch("sendList", { url: "project/list/", page: 1 });
          else if (payload.data.url == "projectgroup/update/")
            dispatch("sendList", { url: "projectgroup/list/", page: 1 });
          else if (payload.data.url == "contract/update/")
            dispatch("sendList", { url: "contract/list/", page: 1 });
          else if (payload.data.url == "contractgroup/update/")
            dispatch("sendList", { url: "contractgroup/list/", page: 1 });
          else if (payload.data.url == "document/update/")
            dispatch("sendList", { url: "document/list/", page: 1 });
          else if (payload.data.url == "invoice/update/")
            dispatch("sendList", { url: "invoice/list/", page: 1 });
          else if (payload.data.url == "customer/update/")
            dispatch("sendList", { url: "customer/list/", page: 1 });
          else if (payload.data.url == "customergroup/update/")
            dispatch("sendList", { url: "customergroup/list/", page: 1 });
          else if (payload.data.url == "ticket/update/")
            dispatch("sendList", { url: "ticket/list/", page: 1 });
          else if (payload.data.url == "person/update/")
            dispatch("sendList", { url: "person/list/", page: 1 });
          else if (payload.data.url == "domain/update/")
            dispatch("sendList", { url: "domain/list/", page: 1 });
          else if (payload.data.url == "plan/update/")
            dispatch("sendList", { url: "plan/list/", page: 1 });
          else if (payload.data.url == "contractcoefficient/update/")
            dispatch("sendListContractSupplement", {
              url: "contractcoefficient/list/",
              id: payload.data.data.contractFk,
            });
          else if (payload.data.url == "contractactivity/update/")
            dispatch("sendListContractAction", {
              url: "contractactivity/list/",
              id: payload.data.data.contractFk,
            });
          else if (payload.data.url == "customercontact/update/")
            dispatch("sendListCustomerContact", {
              url: "customercontact/list/",
              id: payload.data.data.customerFk,
            });
          else if (payload.data.url == "archive/update/")
            dispatch("sendList", { url: "archive/list/", page: 1 });
          else if (payload.data.url == "favorite/update/")
            dispatch("sendList", { url: "favorite/list/", page: 1 });
          else if (payload.data.url == "usergroup/update/")
            dispatch("sendList", { url: "usergroup/list/", page: 1 });
          else if (payload.data.url == "contractpayment/update/")
            dispatch("sendListContractPayment", {
              url: "contractpayment/list/",
              id: payload.data.data.contractFk,
            });
          else if (payload.data.url == "template/update/")
            dispatch("sendList", { url: "template/list/", page: 1 });

          commit("setMessage", {
            message: "درخواست با موفقیت انجام شد",
            color: "success",
            timeOut: 2000,
          });
        } else if (data.data.status == 0) {
          commit("setMessage", {
            message: data.data.message,
            color: "red",
            timeOut: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit("changeLoading", false);
      });
  },

  sendRemoveItem({ dispatch, commit, state, getters }, payload) {
    commit("changeLoading", true);

    let data = {};
    if (payload.data.url == "bank/delete/") data.Id = payload.id.bankId;
    else if (payload.data.url == "ecereceivers/delete/")
      data.Id = payload.id.customerId;
    else if (payload.data.url == "eceemails/delete/")
      data.Id = payload.id.eCEEmailId;
    else if (payload.data.url == "ece/delete/") data.Id = payload.id.eCEId;
    else if (payload.data.url == "account/delete/")
      data.Id = payload.id.accountId;
    else if (payload.data.url == "bankaccount/delete/")
      data.Id = payload.id.bankAccountId;
    else if (payload.data.url == "cashdesk/delete")
      data.Id = payload.id.cashDeskId;
    else if (payload.data.url == "andikator/delete/")
      data.Id = payload.data.andikatorId;
    else if (payload.data.url == "orgpost/delete/")
      data.Id = payload.id.orgPostId;
    else if (payload.data.url == "orgunit/delete/")
      data.Id = payload.id.orgUnitId;
    else if (payload.data.url == "company/delete/")
      data.Id = payload.id.manCompanyId;
    else if (payload.data.url == "blogcategory/delete/")
      data.Id = payload.id.blogCategoryId;
    else if (payload.data.url == "blog/delete/")
      data.Id = payload.id.blogId;
    else if (payload.data.url == "project/delete/")
      data.Id = payload.id.projectId;
    else if (payload.data.url == "projectgroup/delete/")
      data.Id = payload.id.projectId;
    else if (payload.data.url == "contract/delete/")
      data.Id = payload.data.contractId;
    else if (payload.data.url == "contract/deleteuploadfile/")
      data.Id = payload.data.contractAttachmentId;
    else if (payload.data.url == "contractgroup/delete/")
      data.Id = payload.id.contractGroupId;
    else if (payload.data.url == "document/delete/")
      data.Id = payload.id.documentId;
    else if (payload.data.url == "invoice/delete/")
      data.Id = payload.id.invoiceId;
    else if (payload.data.url == "customer/delete/")
      data.Id = payload.data.customerId;
    else if (payload.data.url == "customergroup/delete/")
      data.Id = payload.data.customerGroupId;
    else if (payload.data.url == "ticket/delete/")
      data.Id = payload.id.ticketId;
    else if (payload.data.url == "person/delete/") data.Id = payload.id;
    else if (payload.data.url == "link/delete/") data.Id = payload.id.linkId;
    else if (payload.data.url == "domain/delete/")
      data.Id = payload.id.domainId;
    else if (payload.data.url == "plan/delete/") data.Id = payload.id.planId;
    else if (payload.data.url == "contractcoefficient/delete/")
      data = {
        Id: payload.id.contractCoefficientsId,
        ContractFk: payload.id.contractFk,
      };
    else if (payload.data.url == "contractactivity/delete/")
      data = {
        Id: payload.id.contractActivityId,
        ContractFk: payload.id.contractFk,
      };
    else if (payload.data.url == "contractpayment/delete/")
      data = {
        Id: payload.id.contractPaymentId,
        ContractFk: payload.id.contractFk,
      };
    else if (payload.data.url == "customercontact/delete/")
      data = {
        Id: payload.id.customerContactId,
        Title: payload.id.title,
        Date: payload.id.date,
        CustomerFk: payload.id.customerFk,
      };
    else if (payload.data.url == "archive/delete/")
      data.Id = payload.id.archiveId;
    else if (payload.data.url == "favorite/delete/")
      data.Id = payload.id.favoriteId;
    else if (payload.data.url == "usergroup/delete/")
      data.Id = payload.id.userGroupId;
    else if (payload.data.url == "template/delete/")
      data.Id = payload.data.templateManagerId;
    else if (payload.data.url == "onlineuser/close/")
      data.Id = payload.id.userSessionId;

    data.Language = 0;
    axios
      .delete(`${getters.getUrl}/${payload.data.url}`, {
        data: data,
      })
      .then((data) => {
        if (data.data.status == 1) {
          commit("setMessage", {
            message: "درخواست با موفقیت انجام شد",
            color: "success",
            timeOut: 2000,
          });
          if (payload.data.url == "bank/delete/")
            dispatch("sendList", { url: "bank/list/", page: 1 });
          else if (payload.data.url == "ecereceivers/delete/")
            dispatch("sendList", { url: "ecereceivers/list/", page: 1 });
          else if (payload.data.url == "eceemails/delete/")
            dispatch("sendList", { url: "eceemails/list/", page: 1 });
          else if (payload.data.url == "ece/delete/")
            dispatch("sendList", { url: "ece/list/", page: 1 });
          else if (payload.data.url == "account/delete/")
            dispatch("sendList", { url: "account/list/", page: 1 });
          else if (payload.data.url == "bankaccount/delete/")
            dispatch("sendList", { url: "bankaccount/list/", page: 1 });
          else if (payload.data.url == "cashdesk/delete")
            dispatch("sendList", { url: "cashdesk/list/", page: 1 });
          else if (payload.data.url == "andikator/delete/")
            dispatch("sendList", { url: "andikator/list/", page: 1 });
          else if (payload.data.url == "orgpost/delete/")
            dispatch("sendList", { url: "orgpost/list/", page: 1 });
          else if (payload.data.url == "orgunit/delete/")
            dispatch("sendList", { url: "orgunit/list/", page: 1 });
          else if (payload.data.url == "company/delete/")
            dispatch("sendList", { url: "company/list/", page: 1 });
          else if (payload.data.url == "blogcategory/delete/")
            dispatch("sendList", { url: "blogcategory/list/", page: 1 });
          else if (payload.data.url == "blog/delete/")
            dispatch("sendList", { url: "blog/list/", page: 1 });
          else if (payload.data.url == "blogcategory/delete/")
            dispatch("sendList", { url: "blogcategory/list/", page: 1 });
          else if (payload.data.url == "project/delete/")
            dispatch("sendList", { url: "project/list/", page: 1 });
          else if (payload.data.url == "projectgroup/delete/")
            dispatch("sendList", { url: "projectgroup/list/", page: 1 });
          else if (payload.data.url == "contract/delete/")
            dispatch("sendList", { url: "contract/list/", page: 1 });
          else if (payload.data.url == "contractgroup/delete/")
            dispatch("sendList", { url: "contractgroup/list/", page: 1 });
          else if (payload.data.url == "document/delete/")
            dispatch("sendList", { url: "document/list/", page: 1 });
          else if (payload.data.url == "invoice/delete/")
            dispatch("sendList", { url: "invoice/list/", page: 1 });
          else if (payload.data.url == "customer/delete/")
            dispatch("sendList", { url: "customer/list/", page: 1 });
          else if (payload.data.url == "customergroup/delete/")
            dispatch("sendList", { url: "customergroup/list/", page: 1 });
          else if (payload.data.url == "ticket/delete/")
            dispatch("sendList", { url: "ticket/list/", page: 1 });
          else if (payload.data.url == "person/delete/")
            dispatch("sendList", { url: "person/list/", page: 1 });
          else if (payload.data.url == "link/delete/")
            dispatch("sendList", { url: "link/list/", page: 1 });
          else if (payload.data.url == "domain/delete/")
            dispatch("sendList", { url: "domain/list/", page: 1 });
          else if (payload.data.url == "plan/delete/")
            dispatch("sendList", { url: "plan/list/", page: 1 });
          else if (payload.data.url == "contractcoefficient/delete/")
            dispatch("sendListContractSupplement", {
              url: "contractcoefficient/list/",
              id: payload.id.contractFk,
            });
          else if (payload.data.url == "contractactivity/delete/")
            dispatch("sendListContractAction", {
              url: "contractactivity/list/",
              id: payload.id.contractFk,
            });
          else if (payload.data.url == "contractpayment/delete/")
            dispatch("sendListContractPayment", {
              url: "contractpayment/list/",
              id: payload.id.contractFk,
            });
          else if (payload.data.url == "customercontact/delete/")
            dispatch("sendListCustomerContact", {
              url: "customercontact/list/",
              id: payload.id.customerFk,
            });
          else if (payload.data.url == "archive/delete/")
            dispatch("sendList", { url: "archive/list/", page: 1 });
          else if (payload.data.url == "favorite/delete/")
            dispatch("sendList", { url: "favorite/list/", page: 1 });
          else if (payload.data.url == "usergroup/delete/")
            dispatch("sendList", { url: "usergroup/list/", page: 1 });
          else if (payload.data.url == "template/delete/")
            dispatch("sendList", { url: "template/list/", page: 1 });
          else if (payload.data.url == "onlineuser/close/")
            dispatch("sendList", { url: "onlineuser/list/", page: 1 });
        } else if (data.data.status == 0) {
          commit("setMessage", {
            message: data.data.message,
            color: "red",
            timeOut: 2000,
          });
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        commit("changeLoading", false);
      });
  },
  sendHash(context, payload) {
    context.commit("setHash", payload);
  },

  // latelier
  selectTask(context, task) {
    context.commit("selectTask", task);
  },
  showTaskDetail(context, showTaskDetail) {
    context.commit("updateShowTaskDetail", showTaskDetail);
  },
  showTaskDetailFullscreen(context, showTaskDetailFullscreen) {
    context.commit("updateShowTaskDetailFullscreen", showTaskDetailFullscreen);
  },

  refereshTaskList({ dispatch, commit, state, getters }, payload) {
    axios
      .get(`${getters.getUrl}/tasklist/list/`, {
        params: {
          Count: 1000,
          Page: 1,
          Language: 0,
        },
      })
      .then((data) => {
        commit("setTaskList", data.data.records);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  },
  removeTaskList({ dispatch, commit, state, getters }, payload) {
    commit("removeTaskList", payload.id);
  },

  refereshTaskItems({ dispatch, commit, state, getters }, payload) {
    axios
      .get(`${getters.getUrl}/taskitem/list/`, {
        params: {
          Count: 1000,
          Page: 1,
          Language: 0,
        },
      })
      .then((data) => {
        commit("setTaskItems", data.data.records);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  },
  addTaskItems({ dispatch, commit, state, getters }, payload) {
    commit("addTaskItems", payload);
  },
  removeTaskItem({ dispatch, commit, state, getters }, payload) {
    commit("removeTaskItem", payload.id);
  },
  replaceTaskItem({ dispatch, commit, state, getters }, payload) {
    commit("replaceTaskItem", payload);
  },
  addTaskcheckItem({ dispatch, commit, state, getters }, payload) {
    commit("addTaskcheckItem", payload);
  },
  removeTaskcheckItem({ dispatch, commit, state, getters }, payload) {
    commit("removeTaskcheckItem", payload);
  },
};
