import mutations from "./mutations.js";
import actions from "./actions.js";
import state from "./state.js";
import getters from "./getters.js";

export default {
  state,
  getters,
  mutations,
  actions
}
