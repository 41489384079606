<template>
  <section class="customer-section">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" v-if="customer">
          <v-card>
            <v-card-title> جزییات مشتری </v-card-title>
            <v-card-text>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="شخص / شرکت"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-model="customer.name"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="کد"
                    outlined
                    dense
                    hide-details
                    readonly
                    v-model="customer.code"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="شماره تماس"
                    outlined
                    hide-details
                    dense
                    readonly
                    v-model="customer.tell"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" lg="8">
                  <v-text-field
                    label="شماره همراه"
                    outlined
                    hide-details
                    dense
                    readonly
                    v-model="customer.mobile"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-checkbox
                    label="شماره واتس اپ"
                    hide-details
                    dense
                    readonly
                    v-model="customer.whatsApp"
                  ></v-checkbox>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    label="ایمیل"
                    outlined
                    hide-details
                    dense
                    readonly
                    v-model="customer.email"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    outlined
                    label="توضیحات"
                    rows="4"
                    row-height="30"
                    hide-details
                    readonly
                    v-model="customer.description"
                  ></v-textarea>
                </v-col>
                <!-- <v-col cols="12">
                  <v-autocomplete
                    v-model="editData.data.customerGroupMember"
                    :items="getcustomergroupList.records"
                    item-text="customerGroupTitle"
                    item-value="customerGroupId"
                    outlined
                    dense
                    hide-details
                    chips
                    small-chips
                    label="گروه های مشتری"
                    multiple
                  ></v-autocomplete>
                </v-col> -->
                <!-- <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="2"
                      ><v-icon
                        type="button"
                        class="mt-3"
                        @click="editDataCreatedDateCalendarShow = true"
                      >
                        mdi-calendar
                      </v-icon></v-col
                    >
                    <v-col cols="10">
                      <v-text-field
                        v-model="editData.data.createdDate"
                        id="editData.data.createdDate"
                        type="text"
                        outlined
                        dense
                        @click="editDataCreatedDateCalendarShow = true"
                        label="تاریخ ایجاد"
                        :rules="nameRules"
                        readonly
                      ></v-text-field>
                      <date-picker
                        v-model="editData.data.createdDate"
                        :show="editDataCreatedDateCalendarShow"
                        :auto-submit="true"
                        :editable="true"
                        custom-input="#editData.data.createdDate"
                        @close="editDataCreatedDateCalendarShow = false"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="6">
                  <v-row>
                    <v-col cols="2"
                      ><v-icon
                        type="button"
                        class="mt-3"
                        @click="editDataCreditDateCalendarShow = true"
                      >
                        mdi-calendar
                      </v-icon></v-col
                    >
                    <v-col cols="10">
                      <v-text-field
                        v-model="editData.data.creditDate"
                        id="editData.data.creditDate"
                        type="text"
                        outlined
                        dense
                        @click="editDataCreditDateCalendarShow = true"
                        label="تاریخ اعتبار"
                        :rules="nameRules"
                        readonly
                      ></v-text-field>
                      <date-picker
                        v-model="editData.data.creditDate"
                        :show="editDataCreditDateCalendarShow"
                        :auto-submit="true"
                        :editable="true"
                        custom-input="#editData.data.creditDate"
                        @close="editDataCreditDateCalendarShow = false"
                      />
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-combobox
                    v-model="editData.data.tags"
                    label="برچسب ها"
                    multiple
                    small-chips
                  >
                    <template
                      v-slot:selection="{ attrs, item, parent, selected }"
                    >
                      <v-chip
                        v-bind="attrs"
                        :input-value="selected"
                        label
                        small
                      >
                        <span class="pl-2">
                          {{ item }}
                        </span>
                        <v-icon small @click="parent.selectItem(item)">
                          $delete
                        </v-icon>
                      </v-chip>
                    </template>
                  </v-combobox>
                </v-col> -->
                <!-- <v-col cols="12">
                  <v-divider></v-divider>
                </v-col>
                <v-col cols="12">
                  <v-btn small v-on:click="addAgentEdit"
                    ><v-icon small>mdi-plus</v-icon>افزودن نماینده</v-btn
                  >
                </v-col>
                <v-col cols="12">
                  <v-row
                    v-for="(item, index) in editData.data.customerAgents"
                    :key="index"
                  >
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="نام"
                        outlined
                        hide-details
                        dense
                        v-model="editData.data.customerAgents[index].name"
                        :rules="requiredRule"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="موبایل"
                        outlined
                        hide-details
                        dense
                        v-model="editData.data.customerAgents[index].mobile"
                      ></v-text-field>
                    </v-col>

                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="تلفن"
                        outlined
                        hide-details
                        dense
                        v-model="editData.data.customerAgents[index].tell"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="سمت"
                        outlined
                        hide-details
                        dense
                        v-model="editData.data.customerAgents[index].aff"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="ایمیل"
                        outlined
                        hide-details
                        dense
                        v-model="editData.data.customerAgents[index].email"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12"> <v-divider></v-divider> </v-col>
                  </v-row>
                </v-col> -->
              </v-row>
            </v-card-text>
          </v-card>
          <v-card>
            <v-card-text>
              <v-container>
                <!-- Header -->
                <v-row>
                  <v-col cols="12">
                    <v-card class="pa-4">
                      <v-row>
                        <v-col cols="auto">
                          <router-link
                            :to="{
                              name: 'AllMessage',
                              query: {
                                fromCustomerId: customer.id,
                              },
                            }"
                            ><span>
                              {{ customer.messageCount }}
                              نامه از
                              {{ customer.name }}
                              دریافت شده
                            </span>
                          </router-link>
                        </v-col>
                        <v-col cols="auto">
                          <router-link
                            :to="{
                              name: 'AllMessage',
                              query: {
                                toCustomerId: customer.id,
                              },
                            }"
                          >
                            <span>
                              {{ customer.messageReceiverCount }}
                              نامه به
                              {{ customer.name }}
                              ارسال شده
                            </span>
                          </router-link>
                        </v-col>
                        <v-col cols="auto">
                          <router-link
                            :to="{
                              name: 'ContractList',
                              query: {
                                customerId: customer.id,
                              },
                            }"
                          >
                            <span>
                              {{ customer.contractCount }}
                              قرارداد به طرفیت
                              {{ customer.name }}
                            </span>
                          </router-link>
                        </v-col>
                        <v-col cols="auto">
                          <router-link
                            :to="{
                              name: 'AccountingList',
                              query: {
                                customerId: customer.id,
                              },
                            }"
                          >
                            <span>
                              {{ customer.documentCount }}
                              سند حسابداری
                            </span>
                          </router-link>
                        </v-col>
                        <v-col cols="auto">
                          <router-link
                            :to="{
                              name: 'AccountingCategory',
                              query: {
                                customerId: customer.id,
                              },
                            }"
                          >
                            <span>
                              {{ customer.invoicesCount }}
                              فاکتور
                            </span>
                          </router-link>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-data px-5">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import MenuLeft from "../../../components/MenuLeft";
// import VTable from "vuetify/lib/components/VDataTable/VDataTable";

export default {
  name: "Customer",
  components: {
    // VTable,
    MenuLeft,
  },
  props: {
    id: {
      default: undefined,
    },
  },
  data() {
    return {};
  },
  mounted() {
    this.$store.dispatch("getByIdAsync", {
      id: this.id,
      Language: 0,
    });
  },
  methods: {},
  computed: {
    ...mapState({
      customer: (state) => state.customers.customer,
      loading: (state) => state.customers.customerLoading,
    }),
  },
};
</script>

<style scoped>
.customer-section {
  background-color: #eeeeee !important;
}
.customer-desc {
  white-space: pre-wrap;
}
.pa-3 {
  padding: 12px;
}
.pa-4 {
  padding: 16px;
}
.text-h6 {
  font-size: 20px;
}
</style>
