<template>
  <section class="section__letter">
    <v-container fluid>
      <v-row>
        <!-- دیالوگ تایید حذف -->
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5">آیا اطمینان دارید ؟</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue-darken-1"
                variant="text"
                @click="dialogDelete = false"
                >لغو</v-btn
              >
              <v-btn color="error" variant="text" @click="deleteItemConfirm"
                >بله</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- دیالوگ ارجاعات -->
        <v-dialog v-model="forward" max-width="800">
          <!-- <v-card class="pt-6" flat tile color="basil"> -->
          <v-tabs v-model="tab2" color="basil" height="35">
            <v-tab style="font-size: 0.8rem">
              {{ this.forwardOrForwardDraft == 3 ? "پاسخ" : "ارجاع پیام" }}
            </v-tab>
            <!-- <v-tab style="font-size: 0.8rem"> پیوست </v-tab> -->
          </v-tabs>
          <v-tabs-items v-model="tab2">
            <v-tab-item>
              <v-card flat tile>
                <v-card-title>
                  <v-row> </v-row>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" v-if="this.forwardOrForwardDraft != 3">
                      <v-autocomplete
                        v-model="forwardModel.selectedTo"
                        :items="this.getPersonFilterd"
                        :item-text="
                          (item) => `${item.firstName} ${item.lastName}`
                        "
                        item-value="id"
                        outlined
                        hide-details
                        dense
                        chips
                        small-chips
                        label="گیرندگان"
                        :multiple="
                          this.forwardOrForwardDraft == 1 ? true : false
                        "
                      ></v-autocomplete>
                    </v-col>
                    <v-col cols="12">
                      <v-textarea
                        hide-details
                        outlined
                        label=""
                        v-model="forwardModel.forwardMessage"
                      ></v-textarea>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions class="justify-center">
                  <v-btn v-on:click="forwardMessage" color="success">
                    {{ this.forwardOrForwardDraft == 3 ? "پاسخ" : "ارجاع" }}
                  </v-btn>
                  <v-btn v-on:click="forward = !forward">لغو</v-btn>
                </v-card-actions>
              </v-card>
            </v-tab-item>
            <v-tab-item>
              <v-card flat tile>
                <v-card-text>
                  <v-card tile>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-data-table
                            :headers="attachmentHeaders"
                            hide-default-footer
                          >
                            <template v-slot:item.fileName="{ item }">
                              {{ item.msg.fileName }}
                            </template>
                            <template v-slot:item.btn="{ item }">
                              <v-icon
                                color="error"
                                v-on:click="removeItem(item)"
                              >
                                mdi-trash
                              </v-icon>
                            </template>
                          </v-data-table>
                        </v-col>
                        <v-col cols="12">
                          <v-btn>انتخاب فایل</v-btn>
                          <v-file-input
                            v-show="false"
                            ref="fileInput"
                            accept="image/*"
                          ></v-file-input>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
          <!-- </v-card> -->
        </v-dialog>

        <!-- دیالوگ نامه های مرتبط -->
        <v-dialog value="true" v-if="related" max-width="800" persistent>
          <RelatedMessageList
            :headers="relatedListHeaders"
            :sendList="relatedListLetters"
            :loading="relatedLoading"
            @selectChanged="relatedListSelectedChanged"
            @cancle="closeReleatedModal"
            @pageChanged="relatedPageChanged"
            @search="relatedSearchChanged"
          ></RelatedMessageList>
        </v-dialog>

        <v-col cols="12">
          <v-row>
            <v-col
              v-if="overlay"
              cols="12"
              md="9"
              lg="10"
              class="section__home-data px-5"
            >
              <v-skeleton-loader
                type=" divider, list-item-three-line, card-heading, image, actions"
              ></v-skeleton-loader>
            </v-col>
            <v-col
              v-else
              cols="12"
              md="9"
              lg="10"
              class="section__home-data px-5"
            >
              <v-card>
                <!-- تب های بالای صفحه -->
                <v-app-bar dense flat color="grey" tile>
                  <v-menu offset-y>
                    <template v-slot:activator="{ on }">
                      <v-btn class="ml-1" color="white" v-on="on"
                        ><v-icon small>mdi-email</v-icon> نامه جدید<v-icon
                          >mdi-chevron-down</v-icon
                        ></v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item
                          v-if="
                            this.$store.state.CurrentUser.permissions.includes(
                              'CreateInternalMessage'
                            )
                          "
                          @click="
                            $router
                              .push({
                                name: 'Letter',
                                params: {
                                  create: true,
                                  pagetype: 'AllMessage',
                                  messagetype: 4,
                                  id: 0,
                                },
                              })
                              .catch(() => {})
                          "
                        >
                          <span>نامه داخلی</span>
                        </v-list-item>
                        <v-list-item
                          v-if="
                            this.$store.state.CurrentUser.permissions.includes(
                              'CreateImportMessage'
                            )
                          "
                          @click="
                            $router
                              .push({
                                name: 'Letter',
                                params: {
                                  create: true,
                                  pagetype: 'AllMessage',
                                  messagetype: 5,
                                  id: 0,
                                },
                              })
                              .catch(() => {})
                          "
                        >
                          <span>نامه وارده</span>
                        </v-list-item>
                        <v-list-item
                          v-if="
                            this.$store.state.CurrentUser.permissions.includes(
                              'CreateExportMessage'
                            )
                          "
                          @click="
                            $router
                              .push({
                                name: 'Letter',
                                params: {
                                  create: true,
                                  pagetype: 'AllMessage',
                                  messagetype: 6,
                                  id: 0,
                                },
                              })
                              .catch(() => {})
                          "
                        >
                          <span>نامه صادره</span>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                  <v-btn
                    color="white"
                    class="ml-1"
                    v-on:click="
                      forward = true;
                      forwardOrForwardDraft = 3;
                    "
                    v-if="this.canAnsware"
                  >
                    <v-icon small>mdi-border-color</v-icon>پاسخ
                  </v-btn>
                  <v-btn
                    color="white"
                    class="ml-1"
                    v-on:click="
                      forward = true;
                      forwardOrForwardDraft = 1;
                    "
                    v-if="this.canForward"
                  >
                    <v-icon small>mdi-undo</v-icon>ارجاع
                  </v-btn>
                  <v-menu offset-y v-if="this.canSendToArchive">
                    <template v-slot:activator="{ on }">
                      <v-btn class="ml-1" color="white" v-on="on"
                        ><v-icon small>mdi-email</v-icon>بایگانی<v-icon
                          >mdi-chevron-down</v-icon
                        ></v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item
                          v-for="(item, index) in getArchiveList.records"
                          :key="index"
                          v-on:click="sendToArchive(item)"
                        >
                          <v-list-item-title>{{
                            item.archiveTitle
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="this.canSendToFavorite">
                    <template v-slot:activator="{ on }">
                      <v-btn class="ml-1" color="white" v-on="on"
                        ><v-icon small>mdi-email</v-icon>علاقه مندی<v-icon
                          >mdi-chevron-down</v-icon
                        ></v-btn
                      >
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item
                          v-for="(item, index) in getFavoriteList.records"
                          :key="index"
                          v-on:click="sendToFavorite(item)"
                        >
                          <v-list-item-title>{{
                            item.favoriteTitle
                          }}</v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                  <v-menu offset-y v-if="this.canPrint">
                    <template v-slot:activator="{ on }">
                      <v-btn class="ml-1" color="white" v-on="on">
                        <v-icon small>mdi-printer</v-icon>
                        چاپ
                        <v-icon>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item-group>
                        <v-list-item
                          v-for="(item, index) in getTemplateList.records"
                          :key="index"
                        >
                          <v-list-item-title>
                            <a
                              :href="
                                getUrl +
                                '/message/docx?templateId=' +
                                item.templateManagerId +
                                '&id=' +
                                dataLetter?.messageId +
                                '&token=' +
                                token
                              "
                            >
                              {{ item.templateName }}
                            </a>
                          </v-list-item-title>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                  </v-menu>
                  <v-btn
                    color="error"
                    v-on:click="removeLetter"
                    v-if="this.canRemove"
                    ><v-icon small>mdi-close</v-icon> حذف</v-btn
                  >
                </v-app-bar>
              </v-card>

              <v-card class="pt-6" flat tile color="basil">
                <!-- دکمه های تب های اول نامه -->
                <v-tabs v-model="tab" active-class="grey" color="basil">
                  <v-tab style="font-size: 0.8rem" v-if="!this.isEditable">
                    مشاهده نامه
                  </v-tab>
                  <v-tab style="font-size: 0.8rem" v-if="this.isEditable">
                    نگارش نامه
                  </v-tab>
                  <v-tab style="font-size: 0.8rem">
                    <v-badge
                      color="teal"
                      :value="dataLetter?.messageAttachmentCount ?? 0"
                      :content="dataLetter?.messageAttachmentCount"
                    >
                      پیوست
                    </v-badge>
                  </v-tab>
                  <v-tab style="font-size: 0.8rem">
                    <v-badge
                      color="teal"
                      :value="relatedMessages.length"
                      :content="relatedMessages.length"
                    >
                      نامه های مرتبط
                    </v-badge>
                  </v-tab>
                  <v-tab
                    style="font-size: 0.8rem"
                    v-if="this.canShowForwardedMessage"
                  >
                    <v-badge
                      color="teal"
                      :value="allForwardedMessage.length"
                      :content="allForwardedMessage.length"
                    >
                      ارجاعات
                    </v-badge>
                  </v-tab>
                  <v-tab style="font-size: 0.8rem" v-if="this.canShowEvent">
                    <v-badge
                      color="teal"
                      :value="messageEvent.length"
                      :content="messageEvent.length"
                    >
                      رویداد نامه
                    </v-badge>
                  </v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                  <!-- مشاهده نامه -->
                  <v-tab-item v-if="!this.isEditable">
                    <v-card color="grey" height="900" flat tile>
                      <v-card-title>
                        <v-row>
                          <v-col
                            cols="12"
                            lg="9"
                            style="background-color: #007e8d !important"
                            class="white--text mx-lg-auto text-center mt-5"
                            >{{ dataLetter?.subject }}
                            <!-- <v-icon class="white--text">mdi-pen</v-icon> -->
                          </v-col>
                        </v-row>
                      </v-card-title>
                      <v-card-text>
                        <v-row>
                          <v-col cols="12" lg="10" class="mx-lg-auto">
                            <v-card class="mt-5">
                              <v-row>
                                <v-col cols="12" color="red">
                                  <v-row>
                                    <v-col cols="6" class="pr-6">
                                      <p>
                                        تاریخ:
                                        {{ `${dataLetter?.createdDate}` }}
                                      </p>
                                      <p>
                                        شماره:
                                        {{
                                          `${dataLetter?.andikatorPrefix} ${dataLetter?.number}`
                                        }}
                                      </p>
                                      <p>
                                        پیوست:
                                        {{
                                          dataLetter?.hasMessageAttachment
                                            ? "دارد"
                                            : "ندارد"
                                        }}
                                      </p>
                                    </v-col>
                                    <v-col cols="6">
                                      <p>
                                        فرستنده:
                                        {{ dataLetter?.messageFrom }}
                                      </p>
                                      <p>
                                        اندیکاتور:
                                        {{ dataLetter?.andikatorTitle }}
                                      </p>
                                      <p>
                                        پروژه:
                                        {{ dataLetter?.projectTitle }}
                                      </p>
                                    </v-col>
                                    <v-col cols="12" class="pr-6">
                                      <p>
                                        گیرندگان:
                                        {{ dataLetter?.messageTo }}
                                      </p>
                                      <p>
                                        رونوشت:
                                        {{ dataLetter?.messageCc }}
                                      </p>
                                    </v-col>
                                  </v-row>
                                </v-col>
                                <v-col
                                  cols="12"
                                  lg="8"
                                  class="mx-lg-auto pt-16"
                                >
                                  <p
                                    v-html="dataLetter?.messageBody"
                                    class="letter-view"
                                  ></p>
                                </v-col>
                              </v-row>
                            </v-card>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <!-- ویرایش نامه -->
                  <v-tab-item v-if="this.isEditable" eager>
                    <v-form ref="messageForm">
                      <v-card color="basil" flat>
                        <v-card-text>
                          <v-card tile>
                            <v-container>
                              <v-row>
                                <v-col cols="12">
                                  <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    label="موضوع"
                                    placeholder="موضوع"
                                    v-model="dataLetter.subject"
                                    :rules="requiredRule"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                  cols="12"
                                  lg="6"
                                  v-if="this.messagetype == 5"
                                >
                                  <v-text-field
                                    outlined
                                    hide-details
                                    dense
                                    label="شماره نامه وارده"
                                    placeholder="شماره نامه وارده"
                                    v-model="dataLetter.importedMsgNumber"
                                    :rules="requiredRule"
                                  ></v-text-field>
                                </v-col>

                                <v-col
                                  cols="12"
                                  lg="6"
                                  v-if="this.messagetype == 5"
                                >
                                  <v-row>
                                    <v-col cols="2"
                                      ><v-icon
                                        type="button"
                                        class="mt-3"
                                        @click="
                                          showImportedMsgDateCalendar = true
                                        "
                                      >
                                        mdi-calendar
                                      </v-icon></v-col
                                    >
                                    <v-col cols="10">
                                      <v-text-field
                                        v-model="dataLetter.importedMsgDate"
                                        id="dataLetter.importedMsgDate"
                                        type="text"
                                        outlined
                                        dense
                                        label="تاریخ نامه وارده"
                                        @click="
                                          showImportedMsgDateCalendar = true
                                        "
                                        :rules="requiredRule"
                                        readonly
                                      ></v-text-field>
                                      <date-picker
                                        v-model="dataLetter.importedMsgDate"
                                        :show="showImportedMsgDateCalendar"
                                        :auto-submit="true"
                                        :editable="true"
                                        custom-input="#dataLetter.importedMsgDate"
                                        @close="
                                          showImportedMsgDateCalendar = false
                                        "
                                      />
                                    </v-col>
                                  </v-row>
                                </v-col>

                                <v-col
                                  cols="12"
                                  lg="6"
                                  v-if="this.messagetype == 5"
                                >
                                  <v-select
                                    outlined
                                    hide-details
                                    dense
                                    :items="getReciveMethodList.records"
                                    item-text="reciveMethodTitle"
                                    item-value="reciveMethodId"
                                    label="نحوه دریافت"
                                    v-model="dataLetter.reciveMethodFk"
                                    :rules="requiredRule"
                                  >
                                  </v-select>
                                </v-col>

                                <v-col cols="12" lg="6">
                                  <v-select
                                    outlined
                                    hide-details
                                    dense
                                    :items="
                                      getAndikatorsList.records?.filter(
                                        (x) =>
                                          x.messageTypeFk == this.messagetype
                                      )
                                    "
                                    item-text="andikatorTitle"
                                    item-value="andikatorId"
                                    label="اندیکاتور"
                                    v-model="dataLetter.andikatorFk"
                                    :rules="requiredRule"
                                  >
                                  </v-select>
                                </v-col>

                                <v-col
                                  cols="12"
                                  lg="6"
                                  v-if="this.messagetype == 4"
                                >
                                  <v-checkbox
                                    outlined
                                    hide-details
                                    dense
                                    label="نیاز به پاسخ"
                                    v-model="dataLetter.answerRequest"
                                  >
                                  </v-checkbox>
                                </v-col>
                                <v-col
                                  cols="12"
                                  lg="6"
                                  v-if="this.messagetype == 5"
                                >
                                  <v-select
                                    outlined
                                    hide-details
                                    dense
                                    label="فرستنده"
                                    :items="getCustomerList.records"
                                    item-text="name"
                                    item-value="customerId"
                                    v-model="dataLetter.messageFromCustomerFk"
                                    :rules="requiredRule"
                                  >
                                  </v-select>
                                </v-col>

                                <v-col
                                  cols="12"
                                  lg="6"
                                  v-if="this.messagetype == 6"
                                >
                                  <v-select
                                    outlined
                                    hide-details
                                    dense
                                    label="فرستنده"
                                    :items="this.getPersonFilterd"
                                    :item-text="
                                      (item) =>
                                        `${item.firstName} ${item.lastName}`
                                    "
                                    item-value="id"
                                    v-model="dataLetter.messageFromUserId"
                                    :rules="requiredRule"
                                  >
                                  </v-select>
                                </v-col>

                                <v-col cols="12">
                                  <v-select
                                    outlined
                                    hide-details
                                    dense
                                    label="پروژه"
                                    :items="getProjectList.records"
                                    item-text="projectTitle"
                                    item-value="projectId"
                                    v-model="dataLetter.projectFk"
                                  ></v-select>
                                </v-col>
                                <v-col
                                  cols="12"
                                  v-if="
                                    this.messagetype == 4 ||
                                    this.messagetype == 5
                                  "
                                >
                                  <v-autocomplete
                                    v-model="dataLetter.selectedTo"
                                    :items="this.getPersonFilterd"
                                    :item-text="
                                      (item) =>
                                        `${item.firstName} ${item.lastName}`
                                    "
                                    item-value="id"
                                    outlined
                                    hide-details
                                    dense
                                    label="گیرندگان"
                                    :rules="requiredMultiRule"
                                    multiple
                                  ></v-autocomplete>
                                </v-col>

                                <v-col cols="12" v-if="this.messagetype == 6">
                                  <v-autocomplete
                                    v-model="dataLetter.selectedTo"
                                    :items="getCustomerList.records"
                                    item-text="name"
                                    item-value="customerId"
                                    outlined
                                    hide-details
                                    dense
                                    chips
                                    small-chips
                                    label="گیرندگان"
                                    :rules="requiredRule"
                                    multiple
                                  ></v-autocomplete>
                                </v-col>

                                <v-col cols="12" v-if="this.messagetype == 6">
                                  <v-autocomplete
                                    v-model="dataLetter.exportCustomerAgent"
                                    :items="exportCustomerAgents"
                                    item-text="name"
                                    item-value="customerAgentsId"
                                    outlined
                                    hide-details
                                    dense
                                    chips
                                    small-chips
                                    label="نماینده"
                                  ></v-autocomplete>
                                </v-col>

                                <v-col
                                  cols="12"
                                  v-if="
                                    this.messagetype == 4 ||
                                    this.messagetype == 5
                                  "
                                >
                                  <v-autocomplete
                                    v-model="dataLetter.selectedCC"
                                    :items="this.getPersonFilterd"
                                    :item-text="
                                      (item) =>
                                        `${item.firstName} ${item.lastName}`
                                    "
                                    item-value="id"
                                    outlined
                                    dense
                                    hide-details
                                    chips
                                    small-chips
                                    label="رونوشت"
                                    multiple
                                  ></v-autocomplete>
                                </v-col>
                                <v-col cols="12" v-if="this.messagetype == 6">
                                  <v-autocomplete
                                    v-model="dataLetter.selectedCC"
                                    :items="getCustomerList.records"
                                    item-text="name"
                                    item-value="customerId"
                                    outlined
                                    dense
                                    hide-details
                                    chips
                                    small-chips
                                    label="رونوشت"
                                    multiple
                                  ></v-autocomplete>
                                </v-col>

                                <v-col cols="12">
                                  <my-quill-editor
                                    class="mb-10"
                                    :options="editorOptions"
                                    style="height: 330px"
                                    v-model="dataLetter.messageBody"
                                    :rules="requiredRule"
                                  >
                                  </my-quill-editor>
                                </v-col>
                              </v-row>
                            </v-container>
                          </v-card>
                        </v-card-text>
                      </v-card>
                    </v-form>
                  </v-tab-item>

                  <!-- پیوست -->
                  <v-tab-item eager>
                    <v-card color="basil" flat>
                      <v-card-text>
                        <v-card tile>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-data-table
                                  :headers="attachmentHeaders"
                                  :items="attachments"
                                  hide-default-footer
                                >
                                  <template v-slot:item.fullName="{ item }">
                                    {{ item.fullName }}
                                  </template>
                                  <template v-slot:item.btn="{ item }">
                                    <a
                                      :href="
                                        getUrl +
                                        '/message/download?AttachmentId=' +
                                        item.msg.messageAttachmentId +
                                        '&token=' +
                                        token
                                      "
                                    >
                                      <v-icon> mdi-download </v-icon>
                                    </a>
                                  </template>
                                </v-data-table>
                              </v-col>
                              <v-col cols="12" v-if="this.isEditable">
                                <DropFile
                                  ref="dropfile"
                                  @fileChanged="onFileChanged"
                                />
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <!-- نامه مرتبط -->
                  <v-tab-item>
                    <v-card color="basil" flat>
                      <v-card-text>
                        <v-card tile>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-data-table
                                  :headers="relatedMessageHeaders"
                                  :items="relatedMessages"
                                  hide-default-footer
                                >
                                  <template v-slot:item.description="{ item }">
                                    <v-text-field
                                      class="mt-4"
                                      outlined
                                      dense
                                      v-model="item.description"
                                    ></v-text-field>
                                  </template>
                                  <template
                                    v-slot:[`item.createdDate`]="{ item }"
                                  >
                                    <span>{{
                                      toPersianDateTime(item.createdDate)
                                    }}</span>
                                  </template>
                                  <template v-slot:item.follow="{ item }">
                                    <span>{{
                                      item.follow == true ? "پیرو" : "ارجاء"
                                    }}</span>
                                  </template>
                                  <template v-slot:item.operation="{ item }">
                                    <router-link
                                      :to="{
                                        name: 'Letter',
                                        params: {
                                          create: false,
                                          pagetype: 'AllMyMessage',
                                          messagetype: 4,
                                          id: item.messageId,
                                        },
                                      }"
                                    >
                                      <span> مشاهده </span>
                                    </router-link>
                                    <v-tooltip
                                      bottom
                                      v-if="canAddOrRemoveRelatedMessage"
                                    >
                                      <template
                                        v-slot:activator="{ on, attrs }"
                                      >
                                        <v-icon
                                          v-bind="attrs"
                                          v-on="on"
                                          small
                                          color="error"
                                          @click="
                                            deleteRelatedMessageItem(item)
                                          "
                                          class="mr-2"
                                        >
                                          mdi-delete
                                        </v-icon>
                                      </template>
                                      <span>حذف</span>
                                    </v-tooltip>
                                  </template>
                                </v-data-table>
                              </v-col>
                              <v-col
                                cols="12"
                                v-if="this.canAddOrRemoveRelatedMessage"
                              >
                                <v-btn @click="openReleatedModal"
                                  >ایجاد نامه مرتبط</v-btn
                                >
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <!-- ارجاعات -->
                  <v-tab-item v-if="this.canShowForwardedMessage">
                    <v-card color="basil" flat>
                      <v-card-text>
                        <v-card tile>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <span>متن نامه اصلی : </span>
                                <p
                                  v-html="dataLetter.messageBody"
                                  class="pa-6"
                                ></p>
                                <v-divider />
                              </v-col>
                              <v-col cols="12">
                                <v-data-table
                                  :headers="forwardedMessageHeaders"
                                  :items="allForwardedMessage"
                                  hide-default-footer
                                >
                                  <template v-slot:item.description="{ item }">
                                    <v-text-field
                                      class="mt-4"
                                      outlined
                                      dense
                                      v-model="item.description"
                                    ></v-text-field>
                                  </template>
                                  <template
                                    v-slot:[`item.createdDate`]="{ item }"
                                  >
                                    <span>{{
                                      toPersianDateTime(item.createdDate)
                                    }}</span>
                                  </template>
                                </v-data-table>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>

                  <!-- وقایع -->
                  <v-tab-item v-if="this.canShowEvent">
                    <v-card color="basil" flat>
                      <v-card-text>
                        <v-card tile>
                          <v-container>
                            <v-row>
                              <v-col cols="12">
                                <v-data-table
                                  :headers="messageEventHeaders"
                                  :items="messageEvent"
                                >
                                  <template
                                    v-slot:[`item.createdDate`]="{ item }"
                                  >
                                    <span>{{
                                      toPersianDateTime(item.createdDate)
                                    }}</span>
                                  </template>
                                </v-data-table>
                              </v-col>
                            </v-row>
                          </v-container>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>

                <!-- دکمه های اکشن پایین صفحه -->
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-btn
                        class="mx-1 my-1"
                        compact
                        color="success"
                        v-on:click="sendMessage"
                        v-if="this.canSendMessage"
                        :disabled="
                          this.isDirty && this.dataLetter.messageId != 0
                        "
                      >
                        <v-icon small>mdi-undo</v-icon>
                        ارسال
                      </v-btn>
                      <v-btn
                        class="mx-1 my-1"
                        compact
                        color="blue-grey"
                        v-on:click="saveNumber()"
                        v-if="this.canSaveNumber"
                        :disabled="this.isDirty"
                      >
                        <v-icon small>mdi-undo</v-icon>
                        اختصاص شماره
                      </v-btn>
                      <v-btn
                        class="mx-1 my-1"
                        compact
                        color="blue-grey"
                        v-on:click="sendDraft()"
                        v-if="this.canSendDraft"
                        :disabled="!this.isDirty"
                      >
                        <v-icon small>mdi-pencil</v-icon>
                        ذخیره پیش نویس
                      </v-btn>
                      <v-btn
                        class="mx-1 my-1"
                        compact
                        color="success"
                        v-on:click="sign()"
                        v-if="this.canSign"
                        :disabled="this.isDirty"
                      >
                        <v-icon small>mdi-undo</v-icon>
                        امضاء
                      </v-btn>
                      <v-btn
                        class="mx-1 my-1"
                        compact
                        color="error"
                        v-on:click="removeSign()"
                        v-if="this.canRemoveSign"
                        :disabled="this.isDirty"
                      >
                        <v-icon small>mdi-undo</v-icon>
                        حذف امضاء
                      </v-btn>
                      <v-btn
                        class="mx-1 my-1"
                        compact
                        color="blue-grey"
                        v-on:click="
                          forward = true;
                          forwardOrForwardDraft = 2;
                        "
                        v-if="this.canForwardDraft"
                        :disabled="this.isDirty"
                      >
                        <v-icon small>mdi-undo</v-icon>
                        ارجاء پیش نویس
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-col>
            <v-col cols="12" md="3" lg="2" class="section__home-sidbar">
              <MenuLeft></MenuLeft>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import MenuLeft from "../../components/MenuLeft.vue";
import MyQuillEditor from "../../components/my-vue-quill-editor.vue";
//import LettersList from "./LettersList.vue";
import RelatedMessageList from "./RelatedMessageList.vue";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
//import { quillEditor } from "vue-quill-editor";
import axios from "axios";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
import DropFile from "../../components/DropFile.vue";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";
import moment from "jalali-moment";

export default {
  mixins: [myMixin],
  name: "LetterPage",
  components: {
    MenuLeft,
    //quillEditor,
    //LettersList,
    RelatedMessageList,
    DropFile,
    datePicker: VuePersianDatetimePicker,
    MyQuillEditor,
  },
  props: {
    id: {
      default: -1,
    },
    pagetype: {
      default: -1,
    },
    messagetype: {
      default: -1,
    },
  },
  data() {
    return {
      token: localStorage.getItem("token"),
      editorOptions: {
        placeholder: "متن خود رو وارد نمایید ...",
        //formats: ["direction"],
      },
      overlay: true,
      dialogDelete: false,
      forward: false,
      forwardOrForwardDraft: 1,
      related: false,
      showImportedMsgDateCalendar: false,
      relatedListLetters: [],
      relatedListSelected: [],
      relatedListHeaders: [
        {
          text: "موضوع نامه",
          value: "subject",
          sortable: false,
          align: "center",
          width: 300,
        },

        {
          text: "شماره نامه",
          value: "displayMessageNumber",
          align: "center",
          sortable: false,
          width: 100,
        },
      ],
      orginalRelatedMessages: [],
      relatedMessages: [],

      relatedMessageHeaders: [
        { text: "شماره نامه", value: "number", align: "center" },
        { text: "موضوع نامه", value: "subject", align: "center" },
        { text: "تاریخ نامه", value: "createdDate", align: "center" },
        { text: "فرستنده", value: "createdUser", align: "center" },
        { text: "نوع ارتباط", value: "follow", align: "center" },
        {
          text: "عملیات",
          value: "operation",
          align: "center",
          sortable: false,
        },
      ],

      allForwardedMessage: {},
      messageEvent: {},
      tab: null,
      tab2: null,
      content: "",
      openDialog: false,
      receivers: [],
      attachments: [],
      orginalDataLetter: {},
      dataLetter: {
        txtMsgAttachment: [""],
        txtRelatedMsg: [""],
        isSend: true,
        pageType: "",
        subject: "",
        messageBody: "",
        andikatorFk: "",
        messageId: 0,
        messageTypeFk: 0,
        projectFk: [],
        messageFromManFk: null,
        MessageFromCustomerFk: null,
        reciveMethodFk: 0,
        importedMsgDate: "",
        importedMsgNumber: "",
        selectedTo: [],
        selectedCC: [],
        selectedBcc: [],
        answerRequest: false,
        firstTimeRead: true,
        exportCustomerAgent: null,
      },
      items: [
        "مشاهده نامه",
        "نگارش نامه",
        "پیوست",
        "نامه های مرتبط",
        "ارجاعات",
        "رویداد نامه",
      ],
      items2: ["ارجاع نامه", "پیوست ها"],
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
      attachmentHeaders: [
        {
          text: "ایجاد کننده",
          align: "start",
          value: "fullName",
        },
        {
          text: "نام",
          align: "start",
          value: "msg.fileName",
        },
        {
          text: "توضیحات",
          align: "start",
          value: "msg.fileComment",
        },
        // { text: "شرح", value: "description", align: "center" },
        // { text: "عملیات", value: "operation", align: "center" },
        { text: "دانلود", value: "btn", align: "center" },
      ],
      forwardedMessageHeaders: [
        { text: "متن", value: "forwardMessage", align: "center" },
        { text: "تاریخ", value: "createdDate", align: "center" },
        { text: "فرستنده", value: "creatorUserFullName", align: "center" },
        { text: "گیرندگان", value: "receivedUserFullName", align: "center" },
      ],

      messageEventHeaders: [
        { text: "تاریخ", value: "createdDate", align: "center" },
        { text: "رویداد", value: "eventType", align: "center" },
        { text: "توسط", value: "creatorUserFullName", align: "center" },
      ],
      desserts: [],
      desserts2: [
        {
          row: 1,
          number: "فایل تستی",
          title: "فایل تستی",
          date: "فایل تستی",
          sender: "فایل تستی",
          typeRelation: "فایل تستی",
          operation: "فایل تستی",
          btn: "",
        },
      ],

      exportCustomerAgents: [],
      forwardModel: { forwardMessage: "", selectedTo: [] },
      relatedCurrentPageOption: { page: 1, pageSize: 20 },
      relatedSearch: "",
      relatedLoading: false,
      fileChanged: false,
    };
  },
  mounted() {
    this.setup();
  },
  computed: {
    ...mapGetters([
      "getAndikatorsList",
      "getReciveMethodList",
      "getPersonList",
      "getProjectList",
      "getArchiveList",
      "getFavoriteList",
      "getCustomerAll",
      "getCustomerList",
      "getUrl",
      "getPageListSetting",
      "getTemplateList",
    ]),
    isDirty() {
      return (
        this.fileChanged ||
        JSON.stringify(this.orginalDataLetter.selectedTo) !=
          JSON.stringify(this.dataLetter.selectedTo) ||
        JSON.stringify(this.orginalDataLetter.selectedCC) !=
          JSON.stringify(this.dataLetter.selectedCC) ||
        this.orginalDataLetter.subject != this.dataLetter.subject ||
        this.orginalDataLetter.messageBody != this.dataLetter.messageBody ||
        this.orginalDataLetter.andikatorFk != this.dataLetter.andikatorFk ||
        this.orginalDataLetter.projectFk != this.dataLetter.projectFk ||
        this.orginalDataLetter.reciveMethodFk !=
          this.dataLetter.reciveMethodFk ||
        this.orginalDataLetter.answerRequest != this.dataLetter.answerRequest ||
        this.orginalDataLetter.importedMsgNumber !=
          this.dataLetter.importedMsgNumber ||
        this.orginalDataLetter.importedMsgDate !=
          this.dataLetter.importedMsgDate ||
        this.orginalDataLetter.messageFromCustomerFk !=
          this.dataLetter.messageFromCustomerFk ||
        this.orginalDataLetter.messageFromUserId !=
          this.dataLetter.messageFromUserId ||
        this.orginalDataLetter.exportCustomerAgent !=
          this.dataLetter.exportCustomerAgent ||
        JSON.stringify(
          this.orginalRelatedMessages.map(function (i) {
            return { MessageId: i.messageId, Follow: i.follow };
          })
        ) !=
          JSON.stringify(
            this.relatedMessages.map(function (i) {
              return { MessageId: i.messageId, Follow: i.follow };
            })
          )
      );
    },
    isEditable() {
      return (
        String(this.$route.params.create) === "true" || !this.dataLetter.isSend
      );
    },
    canForward() {
      return this.dataLetter.isSend && this.dataLetter.messageId > 0;
    },
    canAnsware() {
      return this.dataLetter.isSend && this.dataLetter.messageId > 0;
    },
    canForwardDraft() {
      return (
        this.dataLetter.messageId > 0 &&
        !this.dataLetter.isSend &&
        !this.dataLetter.signed
      );
    },
    canSign() {
      return (
        this.dataLetter.messageId > 0 &&
        !this.dataLetter.isSend &&
        !this.dataLetter.signed
      );
    },
    canRemoveSign() {
      return (
        this.dataLetter.messageId > 0 &&
        !this.dataLetter.isSend &&
        this.dataLetter.signed
      );
    },
    canSendDraft() {
      return (
        (this.isEditable || !this.dataLetter.isSend) && !this.dataLetter.signed
      );
    },
    canSaveNumber() {
      // return (
      //   !(
      //     String(this.$route.params.create) === "true" ||
      //     !this.dataLetter.isSend
      //   ) && this.dataLetter.number == null
      // );
      return this.dataLetter.messageId > 0 && this.dataLetter.number == null;
    },
    canSendMessage() {
      return (
        ((this.isEditable || !this.dataLetter.isSend) &&
          this.dataLetter.signed) ||
        this.dataLetter.messageId == 0
      );
    },
    disableSendMessage() {
      return this.dataLetter.messageId > 0;
    },
    canRemove() {
      let userId = this.$store?.state.CurrentUser.userId;
      return (
        this.dataLetter.messageId > 0 &&
        !this.messageEvent?.some(function (e) {
          return e.eventTypeFk == 8 && e.creatorUserId != userId;
        })
      );
    },
    canPrint() {
      return this.dataLetter.messageId > 0;
    },
    canShowEvent() {
      return this.dataLetter.messageId > 0;
    },
    canSendToArchive() {
      return this.dataLetter.isSend && this.dataLetter.messageId > 0;
    },
    canSendToFavorite() {
      return this.dataLetter.isSend && this.dataLetter.messageId > 0;
    },
    canShowForwardedMessage() {
      return this.dataLetter.messageId > 0;
    },
    canAddOrRemoveRelatedMessage() {
      return this.isEditable;
    },
    getPersonFilterd() {
      if (this.messagetype == 4)
        return this.getPersonList.records?.filter(
          (x) => x.id != this.$store.state.CurrentUser.userId
        );
      return this.getPersonList.records;
    },
  },
  methods: {
    setup() {
      this.getDataLetters();
      this.getAndikator();
      this.getReciveMethod();
      this.getPerson();
      this.getProject();
      this.getArchive();
      this.getFavorite();
      this.getListLetters(1, 10, "");
      this.getCustomer();
      this.getTemplate();
    },
    getDataLetters() {
      if (this.id > 0) {
        axios
          .get(`${this.getUrl}/message/get/`, {
            params: {
              Id: this.id,
              Language: 0,
              PageType: this.pagetype,
            },
          })
          .then((data) => {
            this.dataLetter = data.data.record.msg;
            this.dataLetter.createdDate = this.toPersianDateTime(
              data.data.record.msg.createdDate
            );
            if (data.data.record.msg.importedMsgDate)
              this.dataLetter.importedMsgDate = this.toPersianDate(
                data.data.record.msg.importedMsgDate
              );

            this.dataLetter.selectedTo = data.data.record.selectedTo;
            this.dataLetter.selectedCC = data.data.record.selectedCC;
            this.dataLetter.selectedBcc = data.data.record.selectedBcc;

            this.attachments = data.data.attachment;
            this.relatedMessages = data.data.relatedMessage;
            this.allForwardedMessage = data.data.allForwardedMessage;
            this.messageEvent = data.data.messageEvent;

            if (!data.data.record.msg.firstTimeRead) this.tab = 3;

            this.orginalDataLetter = JSON.parse(
              JSON.stringify(this.dataLetter)
            );
            this.orginalRelatedMessages = JSON.parse(
              JSON.stringify(data.data.relatedMessage)
            );
          })
          .catch((error) => {
            console.log(error);
          })
          .finally(() => {
            this.overlay = false;
          });
      } else {
        this.dataLetter = {
          txtMsgAttachment: [""],
          txtRelatedMsg: [""],
          isSend: true,
          pageType: "",
          subject: "",
          messageBody: "",
          andikatorFk: "",
          messageId: 0,
          messageTypeFk: 0,
          projectFk: [],
          messageFromManFk: null,
          MessageFromCustomerFk: null,
          reciveMethodFk: 0,
          importedMsgDate: "",
          importedMsgNumber: "",
          selectedTo: [],
          selectedCC: [],
          selectedBcc: [],
          answerRequest: false,
        };
        this.attachments = [];
        this.allForwardedMessage = {};
        this.messageEvent = {};
        this.tab = null;
        this.relatedListLetters = [];
        this.relatedListSelected = [];
        this.receivers = [];
        this.exportCustomerAgents = [];
        this.relatedMessages = [];
        this.$refs.messageForm?.resetValidation();
        this.overlay = false;
        this.orginalDataLetter = JSON.parse(JSON.stringify(this.dataLetter));
      }
    },
    getFavorite() {
      this.$store.dispatch("sendList", {
        url: "favorite/list/",
        page: 1,
        count: -1,
      });
    },
    getArchive() {
      this.$store.dispatch("sendList", {
        url: "archive/list/",
        page: 1,
        count: -1,
      });
    },
    getAndikator() {
      this.$store.dispatch("sendList", {
        url: "andikator/list/",
        page: 1,
        count: -1,
      });
    },
    getPerson() {
      this.$store.dispatch("sendList", {
        url: "person/list/",
        page: 1,
        count: -1,
      });
    },
    getProject() {
      this.$store.dispatch("sendList", {
        url: "project/list/",
        page: 1,
        count: -1,
      });
    },
    getReciveMethod() {
      this.$store.dispatch("sendList", {
        url: "reciveMethod/list/",
        page: 1,
        count: -1,
      });
    },
    getCustomer() {
      this.$store.dispatch("sendList", {
        url: "customer/list/",
        page: 1,
        count: -1,
      });
    },
    sendToFavorite(item) {
      axios
        .put(`${this.getUrl}/message/setfavorite/`, {
          MessageId: Number(this.id),
          FavoriteId: item.favoriteId,
          Language: 0,
          PageType: this.pagetype,
        })
        .then((response) => {
          if (response.data.status == 1) {
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 3000,
            });
          } else if (response.data.status != 1) {
            this.$store.commit("setMessage", {
              message: response.data.message,
              color: "red",
              timeOut: 3000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendToArchive(item) {
      axios
        .put(`${this.getUrl}/message/setarchive/`, {
          MessageId: Number(this.id),
          ArchiveId: item.archiveId,
          Language: 0,
          PageType: this.pagetype,
        })
        .then((response) => {
          if (response.data.status == 1) {
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 3000,
            });
          } else if (response.data.status != 1) {
            this.$store.commit("setMessage", {
              message: response.data.message,
              color: "red",
              timeOut: 3000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sendMessage() {
      if (this.$refs.messageForm.validate()) {
        if (this.id == 0) {
          axios
            .post(
              `${this.getUrl}/message/save/`,
              {
                Language: 0,
                PageType: this.pagetype,
                txtMsgAttachment: "",
                txtRelatedMsg: "",
                IsSend: "1",
                Attachments: this.$refs.dropfile.files,
                Subject: this.dataLetter.subject,
                MessageBody: this.dataLetter.messageBody,
                AndikatorFk: this.dataLetter.andikatorFk,
                MessageId: this.id,
                MessageTypeFk: this.messagetype,
                ProjectFk: this.dataLetter.projectFk,
                ReciveMethodFk: this.dataLetter.reciveMethodFk,
                RelatedMessages: this.relatedMessages.map(function (i) {
                  return { MessageId: i.messageId, Follow: i.follow };
                }),
                SelectedTo: this.dataLetter.selectedTo,
                ExportCustomerAgent: this.dataLetter.exportCustomerAgent,
                SelectedCC: this.dataLetter.selectedCC,
                ImportedMsgDate: this.dataLetter.importedMsgDate
                  ? moment
                      .from(this.dataLetter.importedMsgDate, "fa", "YYYY/MM/DD")
                      .locale("en")
                      .format("YYYY-MM-DDThh:mm:ss")
                  : null,
                ImportedMsgNumber: this.dataLetter.importedMsgNumber,
                MessageFromManFk: this.dataLetter.messageFromManFk,
                MessageFromCustomerFk: this.dataLetter.messageFromCustomerFk,
                AnswerRequest: this.dataLetter.answerRequest,
                MessageFromUserId: this.dataLetter.messageFromUserId,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((data) => {
              if (data.data.status == 1) {
                this.$store.commit("setMessage", {
                  message: "درخواست با موفقیت انجام شد",
                  color: "success",
                  timeOut: 2000,
                });
                this.$router
                  .push({
                    name: "Letter",
                    params: {
                      create: false,
                      pagetype: "AllMessage",
                      messagetype: this.messagetype,
                      id: data.data.id,
                    },
                  })
                  .catch(() => {});
              } else {
                this.$store.commit("setMessage", {
                  message: data.data.messge,
                  color: "red",
                });
              }
            })
            .catch((error) => {
              this.$store.commit("setMessage", {
                message: error.message,
                color: "red",
                timeOut: 2000,
              });
              console.log(error);
            });
        } else {
          axios
            .post(
              `${this.getUrl}/message/save/`,
              {
                Language: 0,
                PageType: this.pagetype,
                txtMsgAttachment: "",
                txtRelatedMsg: "",
                IsSend: "1",
                Attachments: this.$refs.dropfile.files,
                Subject: this.dataLetter.subject,
                MessageBody: this.dataLetter.messageBody,
                AndikatorFk: this.dataLetter.andikatorFk,
                MessageId: this.id,
                MessageTypeFk: this.dataLetter.messageTypeFk,
                ProjectFk: this.dataLetter.projectFk,
                ReciveMethodFk: this.dataLetter.reciveMethodFk,
                RelatedMessages: this.relatedMessages.map(function (i) {
                  return { MessageId: i.messageId, Follow: i.follow };
                }),
                SelectedTo: this.dataLetter.selectedTo,
                ExportCustomerAgent: this.dataLetter.exportCustomerAgent,
                SelectedCC: this.dataLetter.selectedCC,
                ImportedMsgDate: this.dataLetter.importedMsgDate
                  ? moment
                      .from(this.dataLetter.importedMsgDate, "fa", "YYYY/MM/DD")
                      .locale("en")
                      .format("YYYY-MM-DDThh:mm:ss")
                  : null,
                ImportedMsgNumber: this.dataLetter.importedMsgNumber,
                MessageFromManFk: this.dataLetter.messageFromManFk,
                MessageFromCustomerFk: this.dataLetter.messageFromCustomerFk,
                AnswerRequest: this.dataLetter.answerRequest,
                MessageFromUserId: this.dataLetter.messageFromUserId,
              },
              {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              }
            )
            .then((data) => {
              if (data.data.status == 1) {
                this.$store.commit("setMessage", {
                  message: "درخواست با موفقیت انجام شد",
                  color: "success",
                  timeOut: 2000,
                });
                this.$router.go().catch(() => {});
              } else {
                this.$store.commit("setMessage", {
                  message: data.data.messge,
                  color: "red",
                  timeOut: 2000,
                });
              }
            })
            .catch((error) => {
              this.$store.commit("setMessage", {
                message: error.message,
                color: "red",
                timeOut: 2000,
              });
            });
        }
      }
    },
    sendDraft() {
      if (this.$refs.messageForm.validate()) {
        var data = {
          Language: 0,
          PageType: this.pagetype,
          Attachments: this.$refs.dropfile.files,
          IsSend: "0",
          Subject: this.dataLetter.subject,
          MessageBody: this.dataLetter.messageBody,
          AndikatorFk: this.dataLetter.andikatorFk,
          MessageId: this.id,
          MessageTypeFk: this.messagetype,
          ProjectFk: this.dataLetter.projectFk,
          ReciveMethodFk: this.dataLetter.reciveMethodFk,
          ImportedMsgDate: this.dataLetter.importedMsgDate
            ? moment
                .from(this.dataLetter.importedMsgDate, "fa", "YYYY/MM/DD")
                .locale("en")
                .format("YYYY-MM-DDThh:mm:ss")
            : null,
          ImportedMsgNumber: this.dataLetter.importedMsgNumber,
          SelectedTo: this.dataLetter.selectedTo,
          ExportCustomerAgent: this.dataLetter.exportCustomerAgent,
          SelectedCC: this.dataLetter.selectedCC,
          RelatedMessages: this.relatedMessages.map(function (i) {
            return { MessageId: i.messageId, Follow: i.follow };
          }),
          MessageFromManFk: this.dataLetter.messageFromManFk,
          MessageFromCustomerFk: this.dataLetter.messageFromCustomerFk,
          AnswerRequest: this.dataLetter.answerRequest,
          MessageFromUserId: this.dataLetter.messageFromUserId,
        };

        axios
          .post(`${this.getUrl}/message/save/`, data, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((data) => {
            if (data.data.status == 1) {
              this.$store.commit("setMessage", {
                message: "درخواست با موفقیت انجام شد",
                color: "success",
                timeOut: 2000,
              });
              this.$router
                .push({
                  name: "DraftMessage",
                })
                .catch(() => {});
            } else {
              this.$store.commit("setMessage", {
                message: data.data.messge,
                color: "red",
                timeOut: 2000,
              });
            }
          })
          .catch((error) => {
            this.$store.commit("setMessage", {
              message: error.message,
              color: "red",
              timeOut: 2000,
            });
            console.log(error);
          });
      }
    },
    forwardMessage() {
      axios
        .post(`${this.getUrl}/message/forward/`, {
          MessageId: this.id,
          Language: 0,
          IsSend:
            this.forwardOrForwardDraft == 1
              ? "1"
              : this.forwardOrForwardDraft == 2
              ? "2"
              : "7",
          PageType: this.pagetype,
          ForwardMessage: this.forwardModel.forwardMessage,

          //1  ارجاع
          //2 ارجاء پیش نویس
          //3 پاسخ
          //
          SelectedTo:
            this.forwardOrForwardDraft == 1
              ? this.forwardModel.selectedTo
              : this.forwardOrForwardDraft == 3
              ? [this.dataLetter.messageFromManFk]
              : [this.forwardModel.selectedTo],
          Subject: this.dataLetter.subject,
          MessageBody: this.dataLetter.messageBody,
          AndikatorFk: this.dataLetter.andikatorFk,
          MessageTypeFk: this.messagetype,
          ProjectFk: this.dataLetter.projectFk,
          MessageFromManFk: this.dataLetter.messageFromManFk,
          MessageFromUserId: this.dataLetter.messageFromUserId,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 2000,
            });
            if (
              this.forwardOrForwardDraft == 2 ||
              this.forwardOrForwardDraft == 7
            )
              this.$router
                .push({
                  name: "DraftMessage",
                })
                .catch(() => {});
            else
              this.$router
                .push({
                  name: "MyMessage",
                })
                .catch(() => {});
          } else {
            this.$store.commit("setMessage", {
              message: data.data.messge,
              color: "red",
              timeOut: 2000,
            });
          }
          this.forward = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveNumber() {
      axios
        .post(
          `${this.getUrl}/message/save/`,
          {
            Language: 0,
            IsSend: "6",
            MessageId: this.id,
            AndikatorFk: this.dataLetter.andikatorFk,
          },
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 2000,
            });
            this.dataLetter.number = {};
          } else {
            this.$store.commit("setMessage", {
              message: data.data.messge,
              color: "red",
              timeOut: 2000,
            });
          }
          this.forward = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    sign() {
      axios
        .post(`${this.getUrl}/message/sign/`, {
          Language: 0,
          id: this.id,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 2000,
            });
            this.dataLetter.signed = true;
          } else {
            this.$store.commit("setMessage", {
              message: data.data.messge,
              color: "red",
              timeOut: 2000,
            });
          }
        })
        .catch((error) => {
          this.$store.commit("setMessage", {
            message: error.message,
            color: "red",
            timeOut: 2000,
          });
        });
    },
    removeSign() {
      axios
        .post(`${this.getUrl}/message/removesign/`, {
          Language: 0,
          id: this.id,
        })
        .then((data) => {
          if (data.data.status == 1) {
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 2000,
            });
            this.dataLetter.signed = false;
          } else {
            this.$store.commit("setMessage", {
              message: data.data.messge,
              color: "red",
              timeOut: 2000,
            });
          }
        })
        .catch((error) => {
          this.$store.commit("setMessage", {
            message: error.message,
            color: "red",
            timeOut: 2000,
          });
        });
    },
    removeLetter() {
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      axios
        .delete(`${this.getUrl}/message/delete/`, {
          data: {
            Id: this.id,
            Language: 0,
            TypeNumber: `${this.messagetype}`,
            PageType: this.pagetype,
            IsSend: true,
          },
        })
        .then((data) => {
          console.log(data);
          if (data.data.status == 1) {
            this.$store.commit("setMessage", {
              message: "درخواست با موفقیت انجام شد",
              color: "success",
              timeOut: 3000,
            });
            this.dialogDelete = true;
            this.$router.push({ name: "MyMessage" }).catch(() => {});
          } else if (data.data.status != 1) {
            this.$store.commit("setMessage", {
              message: response.data.message,
              color: "red",
              timeOut: 3000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getListLetters(page, pageSize, search) {
      this.relatedLoading = true;
      axios
        .get(`${this.getUrl}/message/list/`, {
          params: {
            Count: pageSize,
            Page: page,
            Language: 0,
            MessageType: 0,
            Phrase: search,
            TypeNumber: "string",
            PageType: "AllMyMessage",
          },
        })
        .then((data) => {
          this.relatedListLetters = data.data;
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          this.relatedLoading = false;
        });
    },
    relatedPageChanged(option) {
      if (
        option.page != this.relatedCurrentPageOption.page ||
        option.itemsPerPage != this.relatedCurrentPageOption.pageSize
      ) {
        this.getListLetters(
          option.page,
          option.itemsPerPage,
          this.relatedSearch
        );
        this.relatedCurrentPageOption.page = option.page;
        this.relatedCurrentPageOption.pageSize = option.itemsPerPage;
      }
    },
    relatedSearchChanged(search) {
      this.relatedSearch = search;
      this.getListLetters(1, this.relatedCurrentPageOption.pageSize, search);
    },
    openReleatedModal() {
      this.related = true;
    },
    closeReleatedModal() {
      this.related = false;
    },
    relatedListSelectedChanged(items) {
      this.relatedMessages.push(...items);
      //distinct
      this.relatedMessages = this.relatedMessages.filter(function (x, i, a) {
        return a.indexOf(x) == i;
      });
      this.related = false;
    },
    deleteRelatedMessageItem(item) {
      this.relatedMessages = this.relatedMessages.filter(function (i) {
        return i !== item;
      });
    },
    getTemplate() {
      this.$store.dispatch("sendList", {
        url: "template/list/",
        page: 1,
        phrase: "",
      });
    },
    onFileChanged(file) {
      this.fileChanged = file.length > 0;
    },
  },
  watch: {
    "dataLetter.selectedTo"(customerId) {
      this.exportCustomerAgents = this.getCustomerList?.records?.find(
        (x) => x.customerId == customerId
      )?.customerAgents;
    },
    id() {
      this.setup();
    },
    pagetype() {
      this.setup();
    },
    messagetype() {
      this.setup();
    },
    getAndikatorsList: {
      handler(newValue) {
        if (String(this.$route.params.create) === "true") {
          this.dataLetter.andikatorFk = newValue.records?.find(
            (x) => x.messageTypeFk == this.messagetype && x.isDefault
          )?.andikatorId;
        }
      },
    },
    getProjectList: {
      handler(newValue) {
        if (String(this.$route.params.create) === "true") {
          this.dataLetter.projectFk =
            newValue.records?.length > 1 ? newValue.records[0].projectId : null;
        }
      },
    },
    // dataLetter: {
    //   deep: true,
    //   handler(newValue, oldValue) {
    //     debugger;
    //     if (this.firstLoad) this.isDirty = true; // or whatever you need to do when the model becomes dirty
    //     this.firstLoad = true;
    //   },
    // },
  },
};
</script>

<style scoped>
.section__letter {
  background-color: #eeeeee !important;
}
</style>
<style>
.ql-editor.ql-blank::before {
  text-align: right;
}
.ql-editor.ql-blank::before {
  text-align: right;
}
.letter-view {
  direction: ltr;
  text-align: left;
}

.quill-editor {
  direction: ltr;
}
</style>
