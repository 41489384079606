<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            :items="getFavoriteList"
            :insertItemData="addData"
            v-on:getItem="getData"
            v-on:getListBySearch="getSearch"
            :editItemData="editData"
            v-on:getList="getPage"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="formAdd">
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان"
                        hide-details
                        dense
                        outlined
                        v-model="addData.favoriteTitle"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <!-- <v-combobox
                      label="مسیر"
                      hide-details
                      dense
                      outlined
                      v-model="addData.ParentFk"
                    ></v-combobox> -->
                      <v-select
                        label="مسیر"
                        hide-details
                        outlined
                        :items="getFavoriteList.records"
                        item-text="favoriteTitle"
                        item-value="favoriteId"
                        dense
                        v-model="addData.parentFk"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row dense>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان"
                        hide-details
                        dense
                        outlined
                        v-model="editData.data.favoriteTitle"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <!-- <v-combobox
                      label="مسیر"
                      hide-details
                      dense
                      outlined
                      v-model="editData.data.ParentFk"
                    ></v-combobox> -->
                      <v-select
                        label="مسیر"
                        hide-details
                        outlined
                        :items="getFavoriteList.records"
                        item-text="favoriteTitle"
                        item-value="favoriteId"
                        dense
                        v-model="editData.data.parentFk"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft.vue";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "Archive",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      addData: {
        url: "favorite/create/",
        formName: "formAdd",
        favoriteTitle: null,
        parentFk: null,
      },
      editData: {
        url: "favorite/update/",
        formName: "formEdit",
        data: { favoriteTitle: null, parentFk: null },
      },
      removeData: { url: "favorite/delete/" },
      headers: [
        {
          text: "عنوان ",
          align: "right",
          value: "favoriteTitle",
        },
        {
          text: "مسیر کامل",
          align: "center",
          value: "fullName",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  mounted() {
    this.getFavorite();
  },
  computed: {
    ...mapGetters(["getFavoriteList", "getUrl"]),
  },
  methods: {
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "favorite/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "favorite/list/",
        page: 1,
        phrase: search,
      });
    },
    getFavorite() {
      this.$store.dispatch("sendList", {
        url: "favorite/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      axios
        .get(`${this.getUrl}/favorite/get/`, {
          params: {
            Id: item.favoriteId,
            Language: 0,
          },
        })
        .then((response) => {
          this.editData.data = response.data.record;
          this.editData.data.test = this.$refs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
