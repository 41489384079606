<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :items="getEceReceiversList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="form">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        :rules="nameRules"
                        :items="getCustomerList.records"
                        label="مشتری"
                        hide-details
                        outlined
                        item-text="name"
                        item-value="customerId"
                        dense
                        v-model="addData.customerId"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <!-- <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                :rules="nameRules"
                hide-details
                  label="عنوان"
                  outlined
                  dense
                  v-model="editData.data.Title"
                ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                :rules="nameRules"
                hide-details
                  label="ایمیل"
                  outlined
                  dense
                  v-model="editData.data.Email"
                ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                :rules="nameRules"
                hide-details
                  label="نام کاربری"
                  outlined
                  dense
                  v-model="editData.data.UserName"
                ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                :rules="nameRules"
                hide-details
                  label="کلمه عبور"
                  outlined
                  dense
                  v-model="editData.data.Password"
                ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                :rules="nameRules"
                hide-details
                  label="سرور ورودی"
                  outlined
                  dense
                  v-model="editData.data.InputServer"
                ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                :rules="nameRules"
                hide-details
                  label="پورت ورودی"
                  outlined
                  dense
                  v-model="editData.data.InputPort"
                ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                :rules="nameRules"
                hide-details
                  label="سرور خروجی"
                  outlined
                  dense
                  v-model="editData.data.OutputServer"
                ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                            <v-text-field
                :rules="nameRules"
                hide-details
                  label="پورت خروجی"
                  outlined
                  dense
                  v-model="editData.data.OutputPort"
                ></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox
                      label="SSl ورودی"
                      v-model="editData.data.InSSl"
                      hide-details
                      dense
                    ></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox
                      label="SSL خروجی"
                      v-model="editData.data.OutSSl"
                      hide-details
                      dense
                    ></v-checkbox>
                        </v-col>
                        <v-col cols="4">
                            <v-checkbox
                      label="TLS خروجی"
                      v-model="editData.data.OutTls"
                      hide-details
                      dense
                    ></v-checkbox>
                        </v-col>
                    </v-row>
              </v-card-text> -->
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      addData: {
        url: "ecereceivers/create/",
        customerId: null,
        test: this.$refs,
      },
      editData: {
        url: "eceemails/update/",
        data: {
          inputPort: null,
          inputServer: null,
          outputPort: null,
          outputServer: null,
          language: null,
          title: null,
          userName: null,
          email: null,
          password: null,
          inSSl: true,
          outSSl: true,
          outTls: true,
        },
      },
      removeData: { url: "/ecereceivers/delete/" },
      headers: [
        {
          text: "نام مشتری",
          align: "right",
          value: "name",
        },

        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getEceReceiversList", "getUrl", "getCustomerList"]),
  },
  created() {
    // this.getBanks();
    this.getCustomer();
    this.getEceReceivers();
    // this.getAndikator();
    // this.getorgpost();
  },
  methods: {
    getEceReceivers() {
      this.$store.dispatch("sendList", {
        url: "ecereceivers/list/",
        page: 1,
        Phrase: "",
      });
    },
    getorgpost() {
      this.$store.dispatch("sendList", {
        url: "orgpost/list/",
        page: 1,
        Phrase: "",
      });
    },
    getAndikator() {
      this.$store.dispatch("sendList", {
        url: "andikator/list/",
        page: 1,
        Phrase: "",
      });
    },
    getCustomer() {
      this.$store.dispatch("sendList", {
        url: "customer/list/",
        page: 1,
        Phrase: "",
      });
    },
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "ecereceivers/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "ecereceivers/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getData(item) {
      console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/eceemails/get/`, {
          params: {
            Id: item.eCEEmailId,
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response);
          this.editData.data = response.data.record;
          this.editData.data.test = this.$refs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
