import axios from "axios";

export default {
  getByIdAsync(context, payload) {
    context.state.customerLoading = true;
    axios
      .get(`${context.getters.getUrl}/v1/customers/${payload.id}`, {
        params: {
          Language: payload.language,
        },
      })
      .then((response) => {
        context.state.customer = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        context.state.customerLoading = false;
      });
  },
  fetchListAsync(context, payload) {
    context.state.customerLoading = true;
    axios
      .get(`${context.getters.getUrl}/v1/customers/${payload.id}`, {
        params: {
          Language: payload.language,
        },
      })
      .then((response) => {
        context.state.customer = response.data;
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        context.state.customerLoading = false;
      });
  },
};
