export default {
  backgroundApp: "rgb(120, 77, 167 , 0.2)",
  token: null,
  CurrentUser: {},
  BankList: {},
  Accounts: {},
  Ece: {},
  Eceemails: {},
  EceReceivers: {},
  Onlineuser: {},
  usergroupmembers: {},
  DomainList: {},
  BankAccount: {},
  Funds: {},
  Andikators: {},
  ReciveMethod: {},
  orgpost: {},
  orgunit: {},
  company: {},
  blog: {},
  Blogcategory: {},
  ProjectList: {},
  Projectgroup: {},
  customergroup: {},
  Contract: {},
  Contractpayment: {},
  ContractSupplement: {},
  ContractAction: {},
  CustomerContact: {},
  FavoritePersons: {},
  Contractgroup: {},
  Document: {},
  Person: {},
  Customer: {},
  Invoice: {},
  Tickets: {},
  Plans: {},
  Archive: {},
  Favorite: {},
  Pageaction: {},
  System: {},
  Usergroupmember: {},
  usergroup: {},
  Template: {},
  Links: {},
  loading: false,
  message: { message: "", color: "", timeOut: 2000 },
  menu: [],
  mini: {},
  pageListSetting: { count: 100 },
  // #784DA7

  showTaskDetail: false,
  showTaskHistory: false,
  showTaskExport: false,
  showTaskDetailFullscreen: false,
  taskList: [],
  taskItems: [],
  selectedTask: {},
  notifications: [],
};
