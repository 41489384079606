<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            v-on:getListBySearch="getSearch"
            :items="getPlanList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
            add="true"
            edit="true"
            remove="true"
          >
            <template v-slot:addForm>
              <v-form ref="form">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان پلن"
                        outlined
                        dense
                        hide-details
                        v-model="addData.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="قیمت ماهانه (تومان)"
                        outlined
                        dense
                        hide-details
                        v-model="addData.basePrice"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="حداکثر زمان استفاده (روز)"
                        outlined
                        dense
                        hide-details
                        v-model="addData.duration"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="حداکثر فضا (مگابایت)"
                        outlined
                        dense
                        hide-details
                        v-model="addData.maxHDDUsage"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="حداکثر فضای دامین اختصاصی"
                        outlined
                        dense
                        hide-details
                        v-model="addData.maxHDDUsageInOwnDomain"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
            <template v-slot:editForm>
              <v-form ref="formEdit">
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        :rules="nameRules"
                        label="عنوان پلن"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.title"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="قیمت ماهانه (تومان)"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.basePrice"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="حداکثر زمان استفاده (روز)"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.duration"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="حداکثر فضا (مگابایت)"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.maxHDDUsage"
                      ></v-text-field>
                    </v-col>
                    <v-col cols="12" lg="6">
                      <v-text-field
                        label="حداکثر فضای دامین اختصاصی"
                        outlined
                        dense
                        hide-details
                        v-model="editData.data.maxHDDUsageInOwnDomain"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-form>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
import { myMixin } from "../../../mixins.js";
export default {
  name: "Banks",
  mixins: [myMixin],
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      searchTest: "",
      addData: {
        url: "plan/create/",
        isDefault: true,
        maxHDDUsage: null,
        title: null,
        duration: null,
        basePrice: null,
        test: this.$refs,
        maxHDDUsageInOwnDomain: null,
      },
      editData: {
        url: "plan/update/",
        data: {
          isDefault: true,
          maxHDDUsage: null,
          title: null,
          duration: null,
          basePrice: null,
          maxHDDUsageInOwnDomain: null,
        },
      },
      removeData: { url: "plan/delete/" },
      headers: [
        {
          text: "عنوان پلن",
          align: "right",
          value: "title",
        },
        {
          text: "مدت استفاده (روز)",
          align: "center",
          value: "duration",
        },
        {
          text: "قیمت ماهانه (تومان)",
          align: "center",
          value: "basePrice",
        },
        {
          text: "حداکثر فضای مجاز (مگابایت)",
          align: "center",
          value: "maxHDDUsage",
        },
        {
          text: "حداکثر فضای مجاز اختصاصی",
          align: "center",
          value: "maxHDDUsageInOwnDomain",
        },
        {
          text: "پلن پیش فرض",
          align: "center",
          value: "isDefault",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getPlanList", "getUrl"]),
  },
  created() {
    this.getPlan();
  },
  methods: {
    getSearch(search) {
      this.searchTest = search;
      this.$store.dispatch("sendList", {
        url: "plan/list/",
        page: 1,
        phrase: search,
      });
    },
    getPage(item) {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      this.$store.dispatch("sendList", {
        url: "plan/list/",
        page: item,
        phrase: this.searchTest,
      });
    },
    getPlan() {
      this.$store.dispatch("sendList", {
        url: "plan/list/",
        page: 1,
        Phrase: "",
      });
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/plan/get/`, {
          params: {
            Id: item.planId,
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response);
          this.editData.data = response.data.record;
          this.editData.data.test = this.$refs;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
