<template>
  <section class="section__banks">
    <v-container fluid>
      <v-row>
        <v-col cols="12" md="9" lg="10" class="section__banks-data px-5">
          <AccountingSystem
            :headers="headers"
            v-on:getItem="getData"
            v-on:getList="getPage"
            :items="getusergroupmembersList"
            :insertItemData="addData"
            :editItemData="editData"
            :removeItemData="removeData"
            :hasAttachment="false"
            :hasPayment="false"
            :hasSupplement="false"
            :hasAction="false"
            :hasContacts="false"
          >
            <template v-slot:addForm>
              <v-card-text>
                <v-text-field
                  label="عنوان بانک"
                  outlined
                  dense
                  v-model="addData.title"
                ></v-text-field>
              </v-card-text>
            </template>
            <template v-slot:editForm>
              <v-card-text>
                <v-text-field
                  label="عنوان بانک"
                  outlined
                  dense
                  v-model="editData.data.title"
                ></v-text-field>
              </v-card-text>
            </template>
          </AccountingSystem>
        </v-col>
        <v-col cols="12" md="3" lg="2" class="section__banks-sidbar">
          <MenuLeft></MenuLeft>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
import axios from "axios";
import MenuLeft from "../../components/MenuLeft";
import AccountingSystem from "../../components/AccountingSystem.vue";
import { mapGetters } from "vuex";
export default {
  name: "Banks",
  components: {
    MenuLeft,
    AccountingSystem,
  },
  data() {
    return {
      addData: { url: "bank/create/", Title: null },
      editData: { url: "bank/update/", data: { Title: null } },
      removeData: { url: "bank/delete/" },
      headers: [
        {
          text: "عنوان گروه های کاربری",
          align: "center",
          value: "userGroupTitle",
        },
        {
          text: "عنوان سیستم",
          align: "center",
          value: "systemTitle",
        },
        {
          text: "عنوان کاربر",
          align: "center",
          value: "userName",
        },
        { text: "", value: "actions", align: "left", sortable: false },
      ],
    };
  },
  computed: {
    ...mapGetters(["getusergroupmembersList", "getUrl"]),
  },
  created() {
    this.getBanks();
  },
  methods: {
    getPage(item) {
      // console.log(item);

      this.$store.dispatch("sendList", {
        url: "usergroupmembers/list/",
        page: item,
      });
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    getBanks() {
      this.$store.dispatch("sendList", {
        url: "usergroupmembers/list/",
        page: 1,
      });
    },
    getData(item) {
      // console.log(item, "----------------");
      axios
        .get(`${this.getUrl}/bank/get/`, {
          params: {
            Id: item.bankId,
            Language: 0,
          },
        })
        .then((response) => {
          // console.log(response);
          this.editData.data = response.data.record;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.section__banks {
  background-color: #eeeeee !important;
}
</style>
